<template>
  <div class="min-h-full relative">
    <!--AppBar-->
    <app-bar class="bg-primary">
      <div slot="actions">
        <g-button class="btn btn-icon" @click="logout">
          <i class="material-icons">exit_to_app</i>
        </g-button>
      </div>
    </app-bar>

    <!--Body-->
    <div>
      <welcome-banner></welcome-banner>

      <router-link
        :to="{ name: 'coupons', hash: '#my_coupon' }"
        src="/img/2021_Jan_member_daily_promotion.png"
        tag="img"
        class="w-full"
        v-if="showMembershipDailyCouponBanner"
      ></router-link>

      <div v-if="userData" class="mt-6">
        <stamp-collection></stamp-collection>
      </div>

      <div
        v-if="emailUnverified"
        class="mt-10 flex items-center justify-center"
      >
        <img src="../assets/img/email.svg" alt="" class="mr-3" />

        <div>
          <div class="text-red font-bold mb-2">
            立即驗證電郵<br />獲取精彩優惠
          </div>
          <g-button
            class="btn btn-sm btn-yellow w-full"
            :loading="sendingVerificationEmail"
            :disabled="sentVerificationEmail"
            :class="{ 'opacity-40': sentVerificationEmail }"
            @click="sendVerificationEmail"
          >
            {{ sentVerificationEmail ? '已發送電郵' : '重發驗證電郵' }}
          </g-button>
        </div>
      </div>

      <div class="flex flex-col mt-10">
        <featured-button
          :to="{ name: 'coupons' }"
          class="bg-primary text-white"
          title="兌換專區"
          subtitle="儲印花換優惠"
        >
          <template v-slot:image>
            <img src="@/assets/img/redemption_box.png" alt="" />
          </template>
        </featured-button>

        <featured-button
          :to="{ name: 'theme' }"
          class="bg-teal text-white"
          title="更換主題"
          subtitle="個人化體驗"
        >
          <template v-slot:image>
            <img src="@/assets/img/theme.png" alt="" />
          </template>
        </featured-button>
      </div>
    </div>

    <stamp-claim-result :result="claimedStampsResult"></stamp-claim-result>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import AppBar from '@/components/AppBar'
import WelcomeBanner from '@/components/WelcomeBanner'
import FeaturedButton from '@/components/FeaturedButton'

import StampCollection from '@/components/Stamp/Collection'
import StampClaimResult from '@/components/Stamp/ClaimResult'

import { mapState } from 'vuex'

import userDataMixin from '@/mixins/userData'

import moment from 'moment'

export default {
  name: 'Home',

  mixins: [userDataMixin],

  components: {
    GButton,
    AppBar,
    WelcomeBanner,
    FeaturedButton,

    StampCollection,
    StampClaimResult,
  },

  data() {
    return {
      sendingVerificationEmail: false,
      sentVerificationEmail: false,

      claimedStampsResult: null,
    }
  },

  computed: {
    ...mapState(['userData', 'emailUnverified']),

    showMembershipDailyCouponBanner() {
      return moment().isBetween(
        moment.unix(1612108800),
        moment.unix(1614527999)
      )
    },
  },

  methods: {
    logout() {
      this.$store.commit('SHOW_LOADING')

      this.$auth()
        .signOut()
        .then(() => {
          setTimeout(() => {
            this.$store.commit('HIDE_LOADING')
          }, 500)
        })
        .catch(error => {
          this.$sentry.captureException(error)
        })
    },

    sendVerificationEmail() {
      this.sendingVerificationEmail = true
      this.$http
        .post('/verification_email')
        .then(() => {
          this.sentVerificationEmail = true
        })
        .catch(error => {
          this.$sentry.captureException(error)
        })
        .finally(() => {
          this.sendingVerificationEmail = false
        })
    },

    showClaimedStampsResult() {
      this.claimedStampsResult = this.$route.params.claimedStampsResult
      if (this.claimedStampsResult) {
        this.$modal.show('stamp-claim-result')
      }
    },
  },

  created() {
    this.getUserData().finally(() => {
      setTimeout(() => {
        this.$store.commit('HIDE_LOADING')
      }, 500)
    })
  },

  mounted() {
    this.showClaimedStampsResult()
  },
}
</script>
