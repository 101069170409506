<template>
  <modal name="stamp-terms" :minWidth="325" width="90%" height="80%">
    <div class="w-full h-full text-primary p-6 font-bold overflow-y-auto">
      <img
        src="@/assets/img/logo_dark.svg"
        alt=""
        class="mx-auto mb-3 text-xl h-5"
      />

      <h1 class="my-2 text-base">印花收集活動之條款及細則</h1>
      <ol class="list-decimal text-2xs">
        <li>
          Gritus 會員 可在 Gritus 智能售賣機或其網上平台購買指定商品以獲得印花。
        </li>
        <li>每個帳戶最多只可累積 10 個印花。</li>
        <li>集齊所須數量的印花可換領指定的優惠劵。</li>
        <li>每個印花皆有使用期限，任何未作換領之印花則逾期無效。</li>
        <li>印花取得後會由系統自動核實。印花經過核實後方可使用。</li>
        <li>
          可換領的優惠劵須視乎供應情況而定，優惠內容可隨時作出更改，恕不另行通知。
        </li>
        <li>每個印花只限使用 1 次。</li>
        <li>印花不得轉讓，亦不可兌換現金或其他非指定的優惠劵。</li>
        <li>如有任何爭議，Gritus 保留最終決定權。</li>
        <li>本條款及細則的中英文本如有歧異，概以英文本為準。</li>
      </ol>

      <h1 class="my-2 text-base">優惠券兌換之條款及細則</h1>
      <ol class="list-decimal text-2xs">
        <li>優惠劵使用後不設找續，亦不會退回與所兌換商品的價值之差額。</li>
        <li>優惠劵只限使用 1 次。</li>
        <li>Gritus 會員須登入 Gritus 智能售賣機方可使用優惠劵。</li>
        <li>優惠劵只適用於指定商品。</li>
        <li>每個優惠劵皆有使用期限，任何未使用的優惠劵逾期無效。</li>
        <li>
          優惠須視乎供應情況而定，價格及優惠內容可隨時作出更改，恕不另行通知。
        </li>
        <li>
          優惠劵不得轉讓，亦不可兌換現金、印花、其他貨品或其他價值之折扣，或與其他推廣優惠、折扣、優惠券同時使用。
        </li>
        <li>如有任何爭議，Gritus 保留最終決定權。</li>
        <li>本條款及細則的中英文文本如有歧異，概以英文本為準。</li>
      </ol>

      <h1 class="my-2 text-base">Stamp Campaign Terms and Conditions</h1>
      <ol class="list-decimal text-2xs">
        <li>
          Gritus Members can collect Stamps by purchasing selected products on
          Gritus Vending Machine or on Gritus Online Platform.
        </li>
        <li>Each Gritus Member can accumulate 10 Stamps.</li>
        <li>Coupon can be redeemed with a certain number Stamp(s).</li>
        <li>Stamp will be invalid if the Stamp is expired.</li>
        <li>
          Stamp will be automatically validated by the system. Only validated
          Stamps can be used.
        </li>
        <li>
          Offers are subject to availability. The value and offer details may be
          changed from time to time without prior notice.
        </li>
        <li>Each Stamp can be used once.</li>
        <li>
          Stamp cannot be exchanged for cash, other gifts or Coupons, and cannot
          be replaced nor transferred.
        </li>
        <li>In case of dispute, Gritus reserve the right of final decision.</li>
        <li>
          In case of any discrepancy between the English and Chinese versions of
          these terms and conditions, the English version shall prevail.
        </li>
      </ol>

      <h1 class="my-2 text-base">
        Coupon Redemption Campaign Terms and Conditions
      </h1>
      <ol class="list-decimal text-2xs">
        <li>
          Coupons are not redeemable for cash or transferable to other gifts,
          and no changes will be given.
        </li>
        <li>Each Coupon can be used once only.</li>
        <li>
          Gritus Member will need to login to the Gritus Vending Machine to
          enjoy Coupon.
        </li>
        <li>Coupon are applicable in selected products only.</li>
        <li>Coupon will be invalid if the Coupon is expired.</li>
        <li>
          Offers are subject to availability. The value and offer details may be
          changed from time to time without prior notice.
        </li>
        <li>
          Coupon cannot be exchanged for cash, other gifts or Coupons, and
          cannot be replaced nor transferred.
        </li>
        <li>In case of dispute, Gritus reserve the right of final decision.</li>
        <li>
          In case of any discrepancy between the English and Chinese versions of
          these terms and conditions, the English version shall prevail.
        </li>
      </ol>
    </div>

    <div class="absolute top-0 right-0 p-2 cursor-pointer" @click="close">
      <i class="material-icons text-sm sm:text-lg text-primary">close</i>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'StampTerms',

  methods: {
    close() {
      this.$modal.hide('stamp-terms')
    },
  },
}
</script>

<style lang="stylus" scoped></style>
