<template>
  <div class="min-h-full relative">
    <!--AppBar-->
    <app-bar class="bg-teal">
      <div slot="navigation" class="flex-none">
        <g-button class="btn btn-icon" @click="pop">
          <i class="material-icons">arrow_back_ios</i>
        </g-button>
      </div>
    </app-bar>

    <!--Body-->
    <div class="pt-4">
      <div class="px-4">
        <div class="text-base text-gray-600 font-bold">設定售賣機主題</div>
        <div class="text-xs text-red-accent">
          更改設定後須在售賣機上重新登入，方可在售賣機上顯示變更
        </div>
      </div>

      <div class="px-2 sm:px-4 py-2">
        <theme-group
          v-for="group in list"
          :key="`theme_group_${group.name}`"
          class="my-2"
          :title="group.name"
          :themes="group.themes"
          @click="setTheme"
        ></theme-group>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import GButton from '@/components/GButton'
import ThemeGroup from '@/components/ThemeGroup'

import { map, flatten, forEach, find, get } from 'lodash'

export default {
  name: 'Theme',

  components: {
    AppBar,
    GButton,
    ThemeGroup,
  },

  data() {
    return {
      loading: false,

      list: [],
      themes: [],

      selectedThemeId: null,
    }
  },

  watch: {
    loading(newValue) {
      this.$store.commit(newValue ? 'SHOW_LOADING' : 'HIDE_LOADING')
    },

    selectedThemeId(newValue) {
      forEach(this.themes, theme => {
        theme.selected = theme.id === newValue
      })
    },
  },

  created() {
    this.loading = true

    Promise.all([this.getThemeList()]).finally(() => {
      this.loading = false
    })
  },

  methods: {
    pop() {
      this.$router.go(-1)
    },

    getThemeList() {
      return this.$http
        .get('/themes')
        .then(({ data }) => {
          this.list = data.data || []
          const defaultTheme = { id: null, name: '不設定主題', selected: false }

          const themes = get(this.list, '0.themes')
          if (themes) {
            themes.splice(0, 0, defaultTheme)
          }

          this.themes = flatten(map(this.list, 'themes'))
          this.selectedThemeId = get(
            find(this.themes, theme => {
              return theme.selected
            }),
            'id',
            null
          )

          if (!this.selectedThemeId) {
            defaultTheme.selected = true
          }
        })
        .catch(error => {
          this.serverError()
          this.$sentry.captureException(error)
        })
    },

    setTheme(theme) {
      if (this.loading) {
        return
      }

      this.loading = true

      const originalThemeId = this.selectedThemeId
      this.selectedThemeId = theme.id

      const promise = theme.id
        ? this.$http.patch(`/themes/${theme.id}`)
        : this.$http.delete(`/themes`)

      promise
        .catch(error => {
          this.serverError()
          this.selectedThemeId = originalThemeId
          this.$sentry.captureException(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    serverError() {
      this.$store.commit('FLASH_MESSAGE', {
        message: '發生錯誤',
        duration: 5000,
        level: 'error',
      })
    },
  },

  beforeRouteLeave(from, to, next) {
    this.$store.commit('CLEAR_FLASH_MESSAGE')
    next()
  },
}
</script>
