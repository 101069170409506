import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import SignUp from '@/views/SignUp'
import Login from '@/views/Login'
import ForgetPassword from '@/views/ForgetPassword'
import Theme from '@/views/Theme'
import ClaimStamps from '@/views/ClaimStamps'
import Coupon from '@/views/Coupon'

import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/forget_password',
      name: 'forgetPassword',
      component: ForgetPassword,
    },

    {
      path: '/theme',
      name: 'theme',
      component: Theme,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/claim_stamps',
      name: 'claimStamps',
      component: ClaimStamps,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/coupons',
      name: 'coupons',
      component: Coupon,
      meta: {
        requiresAuth: true,
      },
    },
  ],
})

// Login guard
router.beforeEach((to, from, next) => {
  const currentUser = store.state.user
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    const query = {}
    if (to.fullPath && to.fullPath !== '/') {
      query.redirect = to.query.redirect || to.fullPath
    }

    next({
      name: 'login',
      query,
    })
  } else {
    next()
  }
})

export default router
