export default {
  watch: {
    form: {
      handler: function(form) {
        const selected = Object.values(form).some(value => value)

        this.$emit('update', selected)
      },
      deep: true,
    },
  },
}
