<template>
  <div class="flex items-center justify-between text-white h-16 px-2 relative">
    <div class="w-10" v-if="hasNavigationSlot">
      <slot name="navigation"></slot>
    </div>

    <div :class="{ 'pl-2': !hasNavigationSlot }">
      <slot>
        <router-link to="/">
          <img src="@/assets/img/logo_light.svg" alt="" class="h-4 w-auto" />
        </router-link>
      </slot>
    </div>

    <div class="w-10">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBar',

  computed: {
    hasNavigationSlot() {
      return !!this.$slots['navigation']
    },
  },
}
</script>
