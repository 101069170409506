import moment from 'moment'
import { fromPairs, isEmpty, has, get } from 'lodash'

export function getCountdownObject(to, from = null) {
  if (!from) {
    from = moment()
  }

  if (to.isSameOrBefore(from)) {
    return { seconds: 0 }
  }

  const diff = to.diff(from, 's')
  const duration = moment.duration(diff, 's')
  const order = ['days', 'hours', 'minutes', 'seconds']

  return fromPairs(
    order
      .map(unit => {
        const value = duration.get(unit)
        duration.subtract(value, unit)
        return [unit, value]
      })
      .filter(pair => pair[1] !== 0)
  )
}

export function getCountdownString(countdown) {
  if (isEmpty(countdown)) {
    return ''
  }

  const order = ['days', 'hours', 'minutes', 'seconds']
  const locale = {
    days: '天',
    hours: '小時',
    minutes: '分鐘',
    seconds: '秒',
  }

  return order.reduce((str, unit) => {
    if (!has(countdown, unit) || !locale[unit]) {
      return str
    }

    return str + countdown[unit] + locale[unit]
  }, '')
}

export function resolveErrorMessage(error) {
  const errorData = get(error, 'response.data.data', null)

  if (errorData === 'not_enough_stamps') {
    return '印花不足'
  }

  // Unexpected error, capture it
  this.$sentry.captureException(error)

  return '發生錯誤'
}
