<template>
  <modal name="stamp-claim-result" :minWidth="325" width="90%" height="auto">
    <div
      class="w-full h-full text-primary p-6 font-bold overflow-y-auto text-center"
    >
      <div v-if="stamps">
        <div class="flex flex-row justify-center mb-6">
          <stamp-slot
            v-for="(stamp, i) in stamps"
            :key="`claimed_${i}`"
            :stamp="stamp"
            :as-verified="true"
            class="StampClaimResult--stamp mx-2"
          ></stamp-slot>
        </div>
        <div>恭喜！你獲得 {{ count }} 枚印花</div>
      </div>

      <div v-else-if="error === 'stamp_has_been_claimed'">
        你已經領取此印花
      </div>

      <div v-else-if="error === 'score_not_met'">
        要取得 2020 分才可獲得印花，請再接再厲！
      </div>

      <div v-else-if="error === 'exceed_maximum_stamps'">
        你已經獲得 10 個印花，需要先換領優惠券，再收集更多印花。
      </div>

      <div v-else-if="error === 'exceed_maximum_daily_claims'">
        你今天已經獲得印花了，請明天再參與遊戲吧！
      </div>

      <div v-else-if="error === 'invalid_data'">
        無法領取印花
      </div>

      <!-- Unauthorized after user sign up with email address -->
      <div v-else-if="error === 'Unauthorized'">
        請先驗證電郵地址
      </div>

      <div v-else>
        系統繁忙
      </div>

      <div class="mt-6 flex flex-col items-center">
        <g-button class="btn btn-primary btn-xs text-xs" @click="close">
          確定
        </g-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { get } from 'lodash'

import GButton from '@/components/GButton'
import StampSlot from '@/components/Stamp/Slot'

export default {
  name: 'StampClaimResult',

  components: {
    GButton,
    StampSlot,
  },

  props: {
    result: Object,
  },

  computed: {
    stamps() {
      return get(this.result, 'stamps', null)
    },

    count() {
      return (this.stamps && this.stamps.length) || 0
    },

    error() {
      return get(this.result, 'error', null)
    },
  },

  methods: {
    close() {
      this.$modal.hide('stamp-claim-result')
    },
  },
}
</script>

<style lang="stylus" scoped>
.StampClaimResult--stamp
  max-width: 10rem
</style>
