<template>
  <div class="WelcomeBanner bg-indigo text-white text-xs px-2 py-2 relative">
    <div class="WelcomeBanner--marquee whitespace-no-wrap inline-block">
      <span>歡迎，{{ userName }}！</span
      ><span v-if="loginAt">你已經登入GRITUS智能售賣機 - {{ loginAt }}</span>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'
import machineLoginMixin from '@/mixins/machineLogin'

export default {
  name: 'WelcomeBanner',

  mixins: [userMixin, machineLoginMixin],
}
</script>

<style lang="stylus" scoped>
.WelcomeBanner--marquee
  animation: slide-out-left-screen 10s linear infinite

@keyframes slide-out-left-screen {
  0% {
    transform: translateX(100vw)
  }

  100% {
    transform: translateX(-100%)
  }
}
</style>
