<template>
  <div>
    <div class="relative">
      <img
        src="@/assets/img/stamp_coupon_banner_title_zh_hk.png"
        class="h-20 pointer-events-none mx-auto"
      />
    </div>

    <div class="mt-2 mb-4 text-error text-sm text-center">
      請先登入 / 註冊以領取印花
    </div>
  </div>
</template>

<script>
export default {
  name: 'StampBanner',
}
</script>
