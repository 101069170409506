import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),

    userName() {
      return this.user.displayName || 'Gritus User'
    },

    avatar() {
      return {
        backgroundImage: `url(${this.user.photoURL})`,
      }
    },
  },
}
