<template>
  <div class="StampSlot">
    <div class="StampSlot--image p-3/2">
      <img
        v-if="available"
        src="@/assets/img/stamp.png"
        alt=""
        class="w-full h-auto pointer-events-none"
        :class="{ unverified: !asVerified && !verified }"
      />
      <img
        v-else
        src="@/assets/img/stamp_empty.png"
        alt=""
        class="w-full h-auto pointer-events-none"
      />
    </div>
    <div
      v-if="showCountdown && !asVerified"
      class="sm:mx-4 text-center text-2xs sm:text-base whitespace-no-wrap"
      :class="{
        'bg-error text-white': verified && expiredAtDiffAsDays < 4,
        'text-primary': expiredAtDiffAsDays >= 4,
        'text-gray-500': !verified,
      }"
    >
      <span v-if="!verified">未認證</span>
      <span v-else-if="expiredAtDiff > 0">尚餘{{ countdownString }}</span>
      <span v-else>已過期</span>
    </div>
  </div>
</template>

<script>
import { isEmpty, get, pick } from 'lodash'
import moment from 'moment'

import { getCountdownObject, getCountdownString } from '@/helpers'

export default {
  name: 'StampSlot',

  props: {
    stamp: Object,

    asVerified: Boolean,
  },

  computed: {
    available() {
      return !isEmpty(this.stamp)
    },

    verified() {
      return !!get(this.stamp, 'verified_at', null)
    },

    expiredAt() {
      return this.available && this.stamp.expired_at
        ? moment.unix(this.stamp.expired_at)
        : null
    },

    expiredAtDiff() {
      if (!this.expiredAt) {
        return null
      }
      const diff = this.expiredAt.diff(moment(), 's')
      return diff > 0 ? diff : -1
    },

    expiredAtDiffAsDays() {
      if (this.expiredAtDiff > 0) {
        return moment.duration(this.expiredAtDiff, 's').asDays()
      }
      return -1
    },

    showCountdown() {
      return this.expiredAtDiffAsDays >= 0 || (this.available && !this.verified)
    },

    countdownString() {
      if (!this.showCountdown) {
        return null
      }

      let countdown = getCountdownObject(this.expiredAt)

      if (countdown.days > 0) {
        countdown = pick(countdown, 'days')
      } else if (countdown.hours > 0) {
        countdown = pick(countdown, 'hours')
      } else if (countdown.minutes > 0) {
        countdown = pick(countdown, 'minutes')
      }

      return getCountdownString(countdown)
    },
  },
}
</script>

<style lang="stylus" scoped>
.StampSlot--image
  img
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2))

  img.unverified
    opacity: 0.5
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.05))
</style>
