import axios from 'axios'
import store from '@/store'

export default {
  install(Vue) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_HOST,
    })

    instance.interceptors.request.use(
      async config => {
        if (store.state.user) {
          const token = await store.state.user.getIdToken()

          config.headers['Authorization'] = 'Bearer ' + token
        }

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    Vue.prototype.$http = Vue.http = instance
  },
}
