<template>
  <form @submit.prevent="submit">
    <div class="flex">
      <facebook-sign-up
        class="mr-3"
        :disabled="!isSelected('social')"
      ></facebook-sign-up>
      <google-sign-up :disabled="!isSelected('social')"></google-sign-up>
    </div>

    <divider text="或" :disabled="!!selectedProvider"></divider>

    <button
      v-if="showDetails !== 'email'"
      :class="{ 'opacity-20': !isSelected('email') }"
      class="btn btn-sm btn-primary block outline text-sm"
      :disabled="!isSelected('email')"
      type="button"
      @click="showDetails = 'email'"
    >
      使用電郵註冊
    </button>

    <template v-if="showDetails === 'email'">
      <email-sign-up
        ref="email"
        :disabled="!isSelected('email')"
        @update="updateProvider('email', $event)"
      ></email-sign-up>
    </template>

    <divider text="或" :disabled="!!selectedProvider"></divider>

    <button
      v-if="showDetails !== 'phone'"
      :class="{ 'opacity-20': !isSelected('phone') }"
      class="btn btn-sm btn-primary block outline text-sm"
      :disabled="!isSelected('phone')"
      type="button"
      @click="showDetails = 'phone'"
    >
      使用電話號碼註冊
    </button>

    <phone-sign-up
      v-if="showDetails === 'phone'"
      ref="phone"
      :disabled="!isSelected('phone')"
      @update="updateProvider('phone', $event)"
    ></phone-sign-up>

    <div class="text-xs font-medium py-3">
      <span>已經有GRITUS帳號嗎？</span>
      <router-link
        :to="{ path: '/login', query: $route.query }"
        class="text-primary border-b cursor-pointer"
        replace
      >
        請按此登入
      </router-link>
    </div>

    <agreement class="mb-1"></agreement>

    <g-button
      type="submit"
      class="btn btn-lg btn-primary block text-xs"
      :class="{ 'opacity-20': !selectedProvider }"
      :disabled="!selectedProvider"
      :loading="loading"
    >
      註冊
    </g-button>
  </form>
</template>

<script>
import FacebookSignUp from '@/components/Auth/Providers/Facebook'
import GoogleSignUp from '@/components/Auth/Providers/Google'
import EmailSignUp from '@/components/Auth/Providers/Email'
import PhoneSignUp from '@/components/Auth/Providers/Phone'
import Agreement from '@/components/Auth/Agreement'

import Divider from '@/components/Divider'
import GButton from '@/components/GButton'

export default {
  name: 'SignUp',

  components: {
    FacebookSignUp,
    GoogleSignUp,
    EmailSignUp,
    PhoneSignUp,
    Divider,
    GButton,
    Agreement,
  },

  data() {
    return {
      showDetails: null,

      selectedProvider: null,

      loading: false,
    }
  },

  methods: {
    submit() {
      if (this.loading) {
        return
      }

      this.loading = true

      this.$store.commit('SHOW_LOADING')

      this.$refs[this.selectedProvider]
        .auth()
        .then(() => {
          // redirect
          this.$router.replace({
            name: 'home',
            query: this.$route.query,
          })
        })
        .catch(error => {
          this.$store.commit('HIDE_LOADING')

          const ignoreExceptionCodes = [
            'auth/quota-exceeded',
            'auth/captcha-check-failed',
            'auth/missing-phone-number',
            'auth/quota-exceeded',
            'auth/user-disabled',

            'auth/email-already-in-use',
            'auth/invalid-email',
            'auth/weak-password',
          ]

          // ignore some exceptions to be captured
          if (
            !ignoreExceptionCodes.includes(error.code) ||
            ['Invalid form', 'Network Error'].includes(error.message)
          ) {
            return
          }

          this.$sentry.captureException(error)
        })
        .then(() => {
          this.loading = false
        })
    },

    updateProvider(provider, selected) {
      this.selectedProvider = selected ? provider : null
    },

    isSelected(provider) {
      return !this.selectedProvider || this.selectedProvider === provider
    },
  },
}
</script>
