<template>
  <auth-modal>
    <login-form></login-form>
  </auth-modal>
</template>

<script>
import AuthModal from '@/components/Auth/Modal'
import LoginForm from '@/components/Auth/Login'

export default {
  name: 'Login',

  components: {
    AuthModal,
    LoginForm,
  },

  beforeRouteLeave(from, to, next) {
    this.$store.commit('CLEAR_FLASH_MESSAGE')
    next()
  },
}
</script>

<style lang="stylus" scoped></style>
