<template>
  <div class="bg-gray-200 p-4">
    <div
      class="text-gray-700 font-bold border-solid border-b border-gray-400 pb-2"
    >
      {{ title }}
    </div>
    <div class="flex flex-row flex-wrap -m-2 mt-2">
      <div v-for="theme in themes" :key="theme.id" class="w-1/3 sm:w-1/4 p-2">
        <div class="text-2xs text-gray-900 mb-1">{{ theme.name }}</div>
        <div
          class="bg-gray-100 pb-full bg-center bg-no-repeat bg-cover relative rounded"
          :style="{
            backgroundImage: theme.thumbnail
              ? `url(${getfullImagePath(theme.thumbnail)})`
              : null,
          }"
          @click="click(theme)"
        >
          <div
            v-if="theme.selected"
            class="absolute w-full h-full flex items-center justify-center text-red-accent border-red-accent border border-solid text-2xs rounded"
          >
            使用中
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemeGroup',

  props: {
    title: String,

    themes: {
      type: Array,
      default() {
        return []
      },
    },
  },

  methods: {
    click(theme) {
      if (theme.selected) {
        return
      }

      this.$emit('click', theme)
    },

    getfullImagePath(imagePath) {
      if (!imagePath) {
        return null
      }
      return process.env.VUE_APP_ASSETS_HOST + imagePath
    },
  },
}
</script>
