<template>
  <div id="app" class="relative">
    <flash-message></flash-message>
    <router-view />
    <loading v-show="loading"></loading>
  </div>
</template>

<script>
import FlashMessage from '@/components/FlashMessage'
import Loading from '@/components/Loading'

import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    FlashMessage,
    Loading,
  },

  computed: {
    ...mapState(['user', 'loading', 'authInitialized']),
  },

  watch: {
    user(auth) {
      let location = {}
      if (auth) {
        if (this.$route.query.redirect) {
          location = this.$router.resolve(this.$route.query.redirect).location
        } else {
          location = {
            name: 'home',
            query: this.$route.query,
          }
        }
      } else {
        location = {
          name: 'login',
          query: this.$route.query,
        }
      }

      this.$router.replace(location)
    },

    authInitialized() {
      setTimeout(() => {
        this.$store.commit('HIDE_LOADING')
      }, 500)
    },
  },

  mounted() {
    if (!this.authInitialized) {
      this.$store.commit('SHOW_LOADING')
    }
  },
}
</script>
