<template>
  <div :class="{ 'opacity-20': disabled }">
    <div class="text-primary text-xs mb-2">使用電郵登入帳號</div>

    <div class="flex mb-1">
      <input
        class="flex-1 border-2 rounded-lg text-sm px-4 py-1 border-primary text-primary appearance-none"
        :class="{ 'border-error': errors.includes('email') }"
        type="email"
        placeholder="電郵地址"
        v-model.trim="form.email"
        :disabled="disabled"
      />
    </div>

    <div class="flex">
      <input
        class="flex-1 border-2 rounded-lg text-sm px-4 py-1 border-primary text-primary appearance-none"
        :class="{ 'border-error': errors.includes('password') }"
        type="password"
        placeholder="密碼"
        v-model.trim="form.password"
        :disabled="disabled"
      />
    </div>

    <div class="text-right">
      <router-link
        v-if="isLogin"
        :to="{ path: '/forget_password', query: $route.query }"
        class="text-primary text-xs"
      >
        忘記密碼
      </router-link>
    </div>

    <div v-if="!isLogin" class="flex my-1">
      <input
        class="flex-1 border-2 rounded-lg text-sm px-4 py-1 border-primary text-primary appearance-none"
        :class="{ 'border-error': errors.includes('password_confirm') }"
        type="password"
        placeholder="確認密碼"
        v-model.trim="form.password_confirm"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import authProviderMixin from '@/mixins/authProvider'

export default {
  name: 'Email',

  mixins: [authProviderMixin],

  props: {
    isLogin: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirm: '',
      },

      errors: [],
    }
  },

  watch: {
    'form.email'() {
      this.removeError('email')
    },

    'form.password'() {
      this.removeError('password')
    },

    'form.password_confirm'() {
      this.removeError('password_confirm')
    },
  },

  methods: {
    auth() {
      return this.isLogin ? this.login() : this.signUp()
    },

    login() {
      // Validation
      this.errors = []

      if (!this.form.email) {
        this.errors.push('email')
      }

      if (!this.form.password) {
        this.errors.push('password')
      }

      if (this.errors.length > 0) {
        this.$store.commit('FLASH_MESSAGE', {
          message: '請填妥所有資料',
          duration: 5000,
          level: 'error',
        })
      }

      return this.$auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .catch(error => {
          let message = '發生錯誤'
          let duration = 5000
          let level = 'error'

          switch (error.code) {
            case 'auth/wrong-password':
            case 'auth/invalid-email':
            case 'auth/user-disabled':
              message = '電郵地址或密碼不正確'
              break
            case 'auth/user-not-found':
              message = '找不到用戶，請先登記'
              break
          }

          this.$store.commit('FLASH_MESSAGE', {
            message,
            duration,
            level,
          })

          return Promise.reject(error)
        })
    },

    signUp() {
      // Validation
      this.errors = []

      if (!this.form.email) {
        this.errors.push('email')
      }

      if (!this.form.password) {
        this.errors.push('password')
      }

      if (!this.form.password_confirm) {
        this.errors.push('password_confirm')
      }

      if (this.form.password !== this.form.password_confirm) {
        this.errors.push('password_confirm')
      }

      if (this.errors.length > 0) {
        let message = '請填妥所有資料'
        let duration = 5000
        let level = 'error'

        if (this.form.password !== this.form.password_confirm) {
          message = '確認密碼不符'
        }

        this.$store.commit('FLASH_MESSAGE', {
          message,
          duration,
          level,
        })

        return Promise.reject(new Error('Invalid form'))
      }

      return this.$auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .catch(error => {
          let message = '發生錯誤'
          let duration = 5000
          let level = 'error'

          switch (error.code) {
            case 'auth/email-already-in-use':
              message = '電郵地址已被使用'
              break
            case 'auth/invalid-email':
              message = '無效電郵地址'
              break
            case 'auth/weak-password':
              message = '密碼強度不足'
              break
          }

          this.$store.commit('FLASH_MESSAGE', {
            message,
            duration,
            level,
          })

          return Promise.error(error)
        })
    },

    removeError(key) {
      this.errors = this.errors.filter(error => error !== key)
    },
  },
}
</script>

<style lang="stylus" scoped></style>
