<template>
  <div class="min-h-full relative">
    <!--AppBar-->
    <app-bar class="bg-primary"></app-bar>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'

export default {
  name: 'ClaimStamps',

  components: {
    AppBar,
  },

  created() {
    this.claim(this.parseData())
  },

  methods: {
    next({ stamps, error }) {
      this.$router.replace({
        name: 'home',
        params: {
          claimedStampsResult: {
            stamps,
            error,
            path: this.$route.fullPath,
          },
        },
      })
    },

    parseData() {
      const data = this.$route.query.data
      let claimData = null
      if (data) {
        try {
          claimData = JSON.parse(atob(data))
        } catch (error) {
          this.$sentry.captureException(error)
        }
      }

      return claimData
    },

    claim(data) {
      this.$store.commit('SHOW_LOADING')
      if (!data) {
        return this.next({ error: 'invalid_data' })
      }

      return this.$http
        .post('/stamps', data)
        .then(({ data }) => {
          this.next({ stamps: data.data })
        })
        .catch(error => {
          if (error.response && error.response.data) {
            const responseData = error.response.data
            if (responseData.message === 'Invalid request data') {
              return this.next({ error: 'invalid_data' })
            }
            return this.next({
              error: responseData.data || responseData.message,
            })
          }
          this.next({ error: error.toString() })
        })
    },
  },
}
</script>
