<template>
  <div
    class="absolute h-full w-full top-0 left-0 z-10 flex items-center justify-center"
  >
    <div class="absolute top-0 left-0 h-full w-full bg-black opacity-50"></div>
    <div
      class="z-20 bg-white p-6 rounded-lg text-primary flex flex-col items-center justify-center"
    >
      <img src="../assets/img/logo_gritus.svg" alt="" class="mb-2" />

      <div class="w-24 z-10 h-3 mb-1 relative rounded-lg overflow-hidden">
        <div
          class="absolute top-0 left-0 w-full h-full bg-primary opacity-50"
        ></div>

        <div
          class="progress absolute top-0 left-0 w-full h-full bg-primary"
        ></div>
      </div>

      <div class="text-xs font-bold leading-tight">載入中...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style lang="stylus" scoped>
.progress
  transform: translateX(-100%)
  animation-name: loading
  animation-duration 1s
  animation-time-function: ease
  animation-iteration-count: infinite

@keyframes loading
  0%
    transform: translateX(-100%)

  100%
    transform: translateX(100%)
</style>
