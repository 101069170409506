<template>
  <div class="min-h-full relative">
    <!--AppBar-->
    <app-bar class="bg-primary">
      <div slot="navigation" class="flex-none">
        <g-button v-if="canPop" class="btn btn-icon" @click="pop">
          <i class="material-icons">arrow_back_ios</i>
        </g-button>
      </div>
      <div slot="actions">
        <g-button class="btn btn-icon" @click="logout">
          <i class="material-icons">exit_to_app</i>
        </g-button>
      </div>
    </app-bar>

    <!--Body-->
    <div class="mx-3">
      <!--Tab Bar-->
      <div class="flex my-4">
        <div
          class="flex-1 flex items-center justify-center bg-primary py-1 text-white outline-none text-center cursor-pointer  first:rounded-l-full last:rounded-r-full disabled:opacity-60"
          v-for="(tab, index) in tabs"
          :key="`tab_${tab.text}`"
          :class="{ 'opacity-60': selectedTabIndex !== index }"
          @click="changeTab(tab, index)"
        >
          <span class="font-bold text-xs">{{ tab.text }}</span>
        </div>
      </div>
      <!--Tab Body-->

      <div>
        <div v-if="selectedTabIndex === 0" class="flex flex-wrap -m-2">
          <div class="w-full my-2 mx-4 text-gray-600 text-sm tracking-wide">
            你有 {{ verifiedStampCount }} 個印花可換領優惠券
          </div>
          <coupon-type
            class="text-xs p-2 w-3/6"
            v-for="couponType in couponTypes"
            :key="`couponTypes_${couponType.id}`"
            :type="couponType"
            @redeem="redeemCoupon"
          >
          </coupon-type>
        </div>
        <div v-if="selectedTabIndex === 1">
          <template v-if="coupons.length === 0">
            <div class="relative">
              <img src="@/assets/img/no_coupon.svg" alt="" class="w-full" />
              <span class="opacity-20 text-sm absolute absolute-center">
                還未有優惠券
              </span>
            </div>
          </template>

          <member-coupon
            v-for="coupon in coupons"
            :key="`coupon_${coupon.id}`"
            :coupon="coupon"
          >
          </member-coupon>
        </div>
      </div>
    </div>

    <redeem-coupon-modal></redeem-coupon-modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import GButton from '@/components/GButton'
import CouponType from '@/components/Coupon/Type'
import MemberCoupon from '@/components/Coupon/MemberCoupon'
import RedeemCouponModal from '@/components/Coupon/RedeemModal'

import pathMixin from '@/mixins/path'
import userDataMixin from '@/mixins/userData'

import { get } from 'lodash'

export default {
  name: 'Coupon',

  components: {
    MemberCoupon,
    CouponType,
    AppBar,
    GButton,
    RedeemCouponModal,
  },

  mixins: [pathMixin, userDataMixin],

  data() {
    return {
      couponTypes: [],
      coupons: [],

      canPop: false,

      selectedTabIndex: 0,
    }
  },

  computed: {
    tabs() {
      const couponCountText =
        this.coupons.length > 0 ? ` (${this.coupons.length})` : ''

      return [
        {
          text: '換領優惠劵',
          hash: '',
        },
        {
          text: `我的優惠劵` + couponCountText,
          hash: '#my_coupon',
        },
      ]
    },

    verifiedStampCount() {
      const stamps = get(this.$store.state.userData, 'stamps', [])
      return stamps.filter(stamp => stamp.verified_at).length
    },
  },

  methods: {
    pop() {
      this.$router.go(-1)
    },

    logout() {
      this.$store.commit('SHOW_LOADING')

      this.$auth()
        .signOut()
        .then(() => {
          setTimeout(() => {
            this.$store.commit('HIDE_LOADING')
          }, 500)
        })
        .catch(error => {
          this.$sentry.captureException(error)
        })
    },

    fetchAvailableCouponTypes() {
      return this.$http
        .get('/coupon_types?with_redeemable=true')
        .then(({ data }) => {
          this.couponTypes = data.data
          return Promise.resolve()
        })
    },

    fetchMemberCoupons() {
      return this.$http.get('/coupons').then(({ data }) => {
        this.coupons = data.data
      })
    },

    redeemCoupon(type) {
      this.$modal.show('redeem-coupon', {
        couponType: type,
        successHandler: this.redeemCouponSuccessHandler,
      })
    },

    redeemCouponSuccessHandler() {
      // Reset user coupons
      this.coupons = []

      this.$store.commit('SHOW_LOADING')

      Promise.all([this.fetchAvailableCouponTypes(), this.fetchMemberCoupons()])
        .then(() => {
          // Switch to coupon page
          this.selectedTabIndex = 1
        })
        .finally(() => {
          this.$store.commit('HIDE_LOADING')
        })
    },

    changeTab(tab, index) {
      this.selectedTabIndex = index
      this.$router.replace({
        ...this.$route,
        hash: tab.hash,
      })
    },
  },

  created() {
    this.$store.commit('SHOW_LOADING')

    Promise.all([
      this.getUserData(),
      this.fetchAvailableCouponTypes(),
      this.fetchMemberCoupons(),
    ]).finally(() => {
      this.$store.commit('HIDE_LOADING')
    })
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.selectedTabIndex =
        vm.tabs.findIndex(tab => tab.hash === vm.$route.hash) || 0

      vm.canPop = from.name !== 'login'
    })
  },
}
</script>

<style lang="stylus" scoped></style>
