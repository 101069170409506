<template>
  <div>
    <strong class="ml-2">{{ type.name_zh_hk }}</strong>
    <img
      :src="resolveAssetPath(type.image_zh_hk)"
      alt=""
      class="mt-1 mb-3/2"
      style="filter: drop-shadow(0 3px 10px rgba(0, 0, 0, .2))"
    />

    <div class="flex justify-center mb-1/2">
      <button
        class="btn bg-primary text-white font-bold rounded-full px-8 py-1 text-sm disabled:opacity-60"
        :disabled="!type.redeemable"
        @click="redeem"
      >
        兌換
      </button>
    </div>
    <p class="text-center text-2xs">需要印花：{{ type.required_stamps }}</p>
  </div>
</template>

<script>
import pathMixin from '@/mixins/path'

export default {
  name: 'CouponType',

  mixins: [pathMixin],

  props: {
    type: Object,
  },

  methods: {
    redeem() {
      this.$emit('redeem', this.type)
    },
  },
}
</script>

<style lang="stylus" scoped></style>
