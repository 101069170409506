<template>
  <div
    class="relative w-full min-h-full bg-primary flex items-center justify-center"
  >
    <div class="pt-16 pb-12 px-1">
      <div class="relative mx-auto" style="width: 320px;">
        <div
          class="relative w-full h-full bg-white shadow-card rounded-lg z-10 px-3 pb-4 text-gray-900"
        >
          <!--Logo-->
          <img
            src="../../assets/img/logo_dark.svg"
            alt=""
            class="h-4 mx-auto my-11 inline-block w-full"
          />

          <!--Banner-->
          <stamp-banner v-if="showBanner" class="-mt-6"></stamp-banner>

          <!--Auth Body-->
          <slot></slot>
        </div>

        <div
          class="absolute inset-x-0 top-0"
          style="transform: translate(0%, -80%)"
        >
          <img
            src="../../assets/img/login_top_center.svg"
            alt=""
            class="mx-auto"
          />
        </div>

        <div
          class="absolute bottom-0 left-0"
          style="transform: translate(-25%, 60%)"
        >
          <img src="../../assets/img/login_bottom_left.svg" alt="" />
        </div>

        <div
          class="absolute bottom-0 right-0"
          style="transform: translate(45%, 80%)"
        >
          <img src="../../assets/img/login_bottom_right.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="absolute bottom-0 inset-x-0">
      <img src="../../assets/img/logo_light.svg" alt="" class="mx-auto mb-3" />
    </div>
  </div>
</template>

<script>
import StampBanner from '@/components/Stamp/Banner'

export default {
  name: 'AuthModal',

  components: {
    StampBanner,
  },

  data() {
    return {
      showBanner: false,
    }
  },

  created() {
    this.displayBanner()
  },

  methods: {
    displayBanner() {
      const redirect = this.$route.query.redirect
      let location = null
      if (redirect) {
        location = this.$router.resolve(redirect).location
      }
      this.showBanner = location && location.path === '/claim_stamps'
    },
  },
}
</script>
