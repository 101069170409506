<template>
  <router-link
    :to="to"
    class="FeaturedButton h-32 flex flex-row items-center justify-center py-4 px-8"
  >
    <div class="FeaturedButton--image self-stretch relative w-24 mr-4">
      <slot name="image"></slot>
    </div>

    <div class="flex flex-col w-24 ml-4">
      <span
        class="text-xl font-bold line-justify leading-normal h-8 overflow-hidden"
        v-text="title"
      ></span>
      <span
        class="text-sm line-justify leading-normal h-5 overflow-hidden"
        v-text="subtitle"
      ></span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'FeaturedButton',

  props: {
    to: [String, Object],

    title: String,
    subtitle: String,
  },
}
</script>

<style lang="stylus" scoped>
.FeaturedButton
  &--image >>> img
    max-height: 100%
    max-width: 100%
    object-fit: contain
    margin-left: auto
    margin-right: auto
</style>
