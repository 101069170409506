<template>
  <transition name="slide-down">
    <div
      v-if="show"
      class="fixed top-0 left-0 w-full z-20 text-white text-center py-2"
      :class="[backgroundColor]"
    >
      {{ flashMessage.message }}
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FlashMessage',

  data() {
    return {
      timer: null,
    }
  },

  computed: {
    ...mapState(['flashMessage']),

    show() {
      return this.flashMessage
    },

    backgroundColor() {
      return `bg-${this.flashMessage ? this.flashMessage.level : 'error'}`
    },
  },

  watch: {
    flashMessage(newValue) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      if (newValue) {
        this.timer = setTimeout(() => {
          this.$store.commit('CLEAR_FLASH_MESSAGE')

          this.timer = null
        }, newValue.duration)
      }
    },
  },
}
</script>

<style lang="stylus">
.slide-down-enter-active
.slide-down-leave-active
  transition: transform 0.3s ease

.slide-down-enter,
.slide-down-leave-to
  transform: translateY(-100%)
</style>
