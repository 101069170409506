<template>
  <a
    class="btn btn-lg font-social tracking-wider text-xs text-facebook block outline"
    :class="{ 'opacity-20': disabled, 'pointer-events-none': disabled }"
    :disabled="disabled"
    @click="auth"
  >
    <span class="mr-3">
      <img src="../../../assets/img/logo_facebook.svg" alt="" />
    </span>
    Facebook
  </a>
</template>

<script>
export default {
  name: 'Facebook',

  props: {
    disabled: Boolean,
  },

  data() {
    return {
      provider: null,
    }
  },

  created() {
    this.provider = new this.$auth.FacebookAuthProvider()
  },

  methods: {
    auth() {
      if (this.disabled) {
        return
      }

      this.$auth()
        .signInWithRedirect(this.provider)
        .catch(error => {
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              this.$store.commit('FLASH_MESSAGE', {
                message: '請使用其他登入方法',
                duration: 5000,
                level: 'error',
              })
              break
            default:
              this.$store.commit('FLASH_MESSAGE', {
                message: '發生錯誤',
                duration: 5000,
                level: 'error',
              })
          }

          const ignoreErrorCodes = [
            'auth/popup-closed-by-user',
            'auth/popup-blocked',
            'auth/cancelled-popup-request',
          ]

          if (!ignoreErrorCodes.includes(error.code)) {
            this.$sentry.captureException(error)
          }
        })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
