<template>
  <auth-modal>
    <forget-password-form></forget-password-form>
  </auth-modal>
</template>

<script>
import AuthModal from '@/components/Auth/Modal'
import ForgetPasswordForm from '@/components/Auth/ForgetPassword'

export default {
  name: 'ForgetPassword',

  components: {
    AuthModal,
    ForgetPasswordForm,
  },
}
</script>

<style lang="stylus" scoped></style>
