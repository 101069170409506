<template>
  <button :disabled="disabled" @click="$emit('click')">
    <slot v-if="loading" name="loading">
      <div class="loadingSpinner"></div>
    </slot>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: 'GButton',

  props: {
    loading: Boolean,

    disabled: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
$loadingSpinnerDiameter=22px
$loadingSpinnerStrokeWidth=2px
$loadingSpinnerStrokeColor=#FFF
$loadingSpinnerStrokeStyle= solid // solid, dashed, dotted
$loadingSpinnerSpeed=.7s

.loadingSpinner
  border: $loadingSpinnerStrokeWidth $loadingSpinnerStrokeStyle $loadingSpinnerStrokeColor
  border-top-color: rgba(0, 0, 0, 0)
  border-left-color: rgba(0, 0, 0, 0)
  width: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2)
  height: $loadingSpinnerDiameter - ($loadingSpinnerStrokeWidth * 2)
  border-radius: 50%
  animation: loadingSpinner $loadingSpinnerSpeed infinite linear

@keyframes loadingSpinner
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
</style>
