export default {
  methods: {
    resolveAssetPath(path) {
      if (!path) {
        return null
      }
      return process.env.VUE_APP_ASSETS_HOST + path
    },
  },
}
