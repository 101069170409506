<template>
  <div class="divider line text-gray-600" :class="classes">
    <div v-if="text" class="px-4 text-gray-900">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Divider',

  props: {
    text: String,
    disabled: Boolean,
  },

  computed: {
    classes() {
      if (this.disabled) {
        return ['opacity-20']
      }

      return ['opacity-40']
    },
  },
}
</script>

<style lang="stylus" scoped>
.divider
  display: flex
  align-items: center

  &:before,
  &:after
    content: ""
    flex: 1

.line
  align-items: center
  margin: .5em 0

  &:before,
  &:after
    background-color: currentColor
    height: 1px
</style>
