<template>
  <auth-modal>
    <sign-up-form></sign-up-form>
  </auth-modal>
</template>

<script>
import AuthModal from '@/components/Auth/Modal'
import SignUpForm from '@/components/Auth/SignUp'

export default {
  name: 'SignUp',

  components: {
    AuthModal,
    SignUpForm,
  },

  beforeRouteLeave(from, to, next) {
    this.$store.commit('CLEAR_FLASH_MESSAGE')
    next()
  },
}
</script>

<style lang="stylus" scoped></style>
