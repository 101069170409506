<template>
  <modal
    name="redeem-coupon"
    :click-to-close="false"
    :adaptive="true"
    width="100%"
    height="100%"
    @before-open="beforeOpen"
  >
    <div class="flex flex-col items-center">
      <p class="text-white font-bold text-base text-center leading-tight">
        確定要使用{{ couponType.required_stamps }}個印花換取<br />
        <span class="text-yellow-accent mr-1">
          {{ couponType.name_zh_hk }}
        </span>
        優惠券嗎？
      </p>

      <img
        :src="resolveAssetPath(couponType.image_zh_hk)"
        alt=""
        class="w-40 my-4"
      />

      <div class="flex">
        <g-button
          class="btn btn-xs btn-red-accent rounded-full text-sm font-bold mr-5"
          :loading="loading"
          :disabled="loading"
          @click="close"
        >
          取消
        </g-button>
        <g-button
          class="btn btn-xs btn-primary rounded-full text-sm font-bold"
          :loading="loading"
          :disabled="loading"
          @click="confirm"
        >
          確定
        </g-button>
      </div>
    </div>
  </modal>
</template>

<script>
import GButton from '@/components/GButton'
import pathMixin from '@/mixins/path'
import { resolveErrorMessage } from '@/helpers'

export default {
  name: 'CouponRedeemModal',

  components: {
    GButton,
  },

  mixins: [pathMixin],

  data() {
    return {
      couponType: {},

      loading: false,

      successHandler: null,
    }
  },

  methods: {
    close() {
      this.$modal.hide('redeem-coupon')
    },

    beforeOpen(event) {
      this.couponType = event.params.couponType
      this.successHandler = event.params.successHandler
    },

    confirm() {
      this.loading = true

      this.$http
        .post('/coupons', {
          coupon_type_id: this.couponType.id,
        })
        .then(() => {
          this.successHandler()
        })
        .catch(error => {
          this.$store.commit('FLASH_MESSAGE', {
            message: resolveErrorMessage(error),
            duration: 5000,
            level: 'error',
          })
        })
        .finally(() => {
          this.loading = false

          this.close()
        })
    },
  },
}
</script>

<style lang="stylus">
.v--modal-overlay[data-modal="redeem-coupon"]
  background-color: rgba(0, 0, 0, 0.7)

  .v--modal
    background-color: transparent
    box-shadow: none
    display: flex
    align-items: center
    justify-content: center
</style>
