<template>
  <form @submit.prevent="submit">
    <div class="flex">
      <facebook-login
        class="mr-3"
        :disabled="!isSelected('social')"
      ></facebook-login>
      <google-login :disabled="!isSelected('social')"></google-login>
    </div>

    <divider text="或" :disabled="!!selectedProvider"></divider>

    <email-login
      ref="email"
      is-login
      :disabled="!isSelected('email')"
      @update="updateProvider('email', $event)"
    ></email-login>

    <divider text="或" :disabled="!!selectedProvider"></divider>

    <phone-login
      ref="phone"
      :disabled="!isSelected('phone')"
      is-login
      @update="updateProvider('phone', $event)"
    ></phone-login>

    <div class="text-xs font-medium py-3">
      <span>還未有GRITUS帳號嗎？</span>
      <router-link
        :to="{ path: '/signup', query: $route.query }"
        class="text-primary border-b cursor-pointer"
        replace
      >
        請按此註冊
      </router-link>
    </div>

    <g-button
      type="submit"
      class="btn btn-lg btn-primary block text-xs"
      :class="{ 'opacity-20': !selectedProvider }"
      :disabled="!selectedProvider || loading"
    >
      登入
    </g-button>
  </form>
</template>

<script>
import FacebookLogin from '@/components/Auth/Providers/Facebook'
import GoogleLogin from '@/components/Auth/Providers/Google'
import EmailLogin from '@/components/Auth/Providers/Email'
import PhoneLogin from '@/components/Auth/Providers/Phone'

import Divider from '@/components/Divider'
import GButton from '@/components/GButton'

export default {
  name: 'Login',

  components: {
    Divider,
    GButton,
    FacebookLogin,
    GoogleLogin,
    EmailLogin,
    PhoneLogin,
  },

  data() {
    return {
      selectedProvider: null,

      loading: false,
    }
  },

  methods: {
    submit() {
      if (!this.selectedProvider) {
        // show errors
        alert('Please fill in something')
        return
      }

      if (this.loading) {
        return
      }

      this.loading = true

      this.$store.commit('SHOW_LOADING')

      this.$refs[this.selectedProvider]
        .auth()
        .then(() => {
          // redirect
          this.$router.replace({
            name: 'home',
            query: this.$route.query,
          })
        })
        .catch(error => {
          this.$store.commit('HIDE_LOADING')

          const ignoreExceptionCodes = [
            'auth/wrong-password',
            'auth/user-not-found',
            'auth/invalid-email',
            'auth/user-disabled',
          ]

          // ignore some exceptions to be captured
          if (
            !ignoreExceptionCodes.includes(error.code) ||
            ['Invalid form', 'Network Error'].includes(error.message)
          ) {
            return
          }

          this.$sentry.captureException(error)
        })
        .then(() => {
          this.loading = false
        })
    },

    updateProvider(provider, selected) {
      this.selectedProvider = selected ? provider : null
    },

    isSelected(provider) {
      return !this.selectedProvider || this.selectedProvider === provider
    },
  },
}
</script>
