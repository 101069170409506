<template>
  <div
    class="text-center text-2xs sm:text-base whitespace-no-wrap"
    :class="{
      'bg-error text-white': expiredAtDiffAsDays < 4,
      'text-primary': expiredAtDiffAsDays >= 4,
    }"
  >
    <span v-if="expiredAtDiff > 0">尚餘：{{ countdownString }}</span>
    <span v-else>已過期</span>
  </div>
</template>

<script>
import moment from 'moment'
import { getCountdownObject, getCountdownString } from '@/helpers'
import { pick } from 'lodash'

export default {
  name: 'ExpiryCountDown',

  props: {
    expiredAt: Number,
  },

  computed: {
    localExpiredAt() {
      return this.expiredAt ? moment.unix(this.expiredAt) : null
    },

    expiredAtDiff() {
      if (!this.localExpiredAt) {
        return null
      }
      const diff = this.localExpiredAt.diff(moment(), 's')
      return diff > 0 ? diff : -1
    },

    expiredAtDiffAsDays() {
      if (this.expiredAtDiff > 0) {
        return moment.duration(this.expiredAtDiff, 's').asDays()
      }
      return -1
    },

    countdownString() {
      let countdown = getCountdownObject(this.localExpiredAt)

      // > 1day => day + hours
      // > 1hour => hour
      // > 1minute => minutes
      // second

      if (countdown.days > 0) {
        countdown = pick(countdown, ['days', 'hours'])
      } else if (countdown.hours > 0) {
        countdown = pick(countdown, 'hours')
      } else if (countdown.minutes > 0) {
        countdown = pick(countdown, 'minutes')
      } else {
        countdown = pick(countdown, 'seconds')
      }

      return getCountdownString(countdown)
    },
  },
}
</script>

<style lang="stylus" scoped></style>
