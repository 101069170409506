export default {
  methods: {
    getUserData(forceLogin = false) {
      const data = {}
      let query = JSON.parse(JSON.stringify(this.$route.query))

      if (this.$route.query.token) {
        data.token = this.$route.query.token
        delete query.token
        forceLogin = true
      }

      let p = Promise.resolve()

      if (this.$store.state.userData === null || forceLogin) {
        this.$store.commit('UPDATE_USER_DATA', null)
        p = this.$http
          .post('/login', data)
          .then(({ data }) => {
            this.$store.commit('UPDATE_USER_DATA', data.data)
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 401) {
                this.$store.commit('SET_EMAIL_UNVERIFIED')
              }
            }
          })
      }

      return p.finally(() => {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: query,
        })
      })
    },
  },
}
