import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VModal from 'vue-js-modal'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import firebase from '@/plugins/firebase'
import httpClient from '@/plugins/httpClient'

import './registerServiceWorker'

import '@/assets/css/tailwind.css'
import '@/assets/css/main.styl'

require('promise.prototype.finally').shim()

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: process.env.NODE_ENV === 'development',
    }),
  ],
  beforeSend: (event, hint) => {
    if (process.env.NODE_ENV === 'development') {
      /* eslint-disable no-console */
      console.error(hint.originalException || hint.syntheticException)
    }
    return event
  },
  ignoreErrors: [
    /^Network Error$/,
    '"location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    'There is no user record corresponding to this identifier. The user may have been deleted.',
  ],
})

Vue.use(firebase)
Vue.use(httpClient)
Vue.use(VModal)

Vue.config.productionTip = false

Vue.prototype.$sentry = Vue.sentry = Sentry

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
