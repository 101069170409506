<template>
  <div :class="{ 'opacity-20': disabled }">
    <div v-if="isLogin" class="text-xs text-primary mb-2">
      使用電話號碼登入
    </div>

    <div class="flex mb-1">
      <div
        class="border-2 border-r-0 px-4 py-1 text-sm text-primary rounded-lg rounded-r-none"
        :class="{ 'border-error': errors.includes('phone_number') }"
      >
        +852
      </div>
      <input
        class="flex-1 border-2 rounded-none text-sm px-4 py-1 border-primary text-primary appearance-none min-w-0"
        :class="{ 'border-error': errors.includes('phone_number') }"
        type="tel"
        placeholder="請輸入電話號碼"
        :disabled="disabled"
        @keydown.enter.prevent="sendVerificationCode"
        v-model.trim="form.phone_number"
      />
      <g-button
        id="send-sms-code"
        class="btn-xs btn-primary rounded-r-lg text-2xs font-light border-2 border-primary border-l-0"
        :class="{ 'border-error': errors.includes('phone_number') }"
        type="button"
        :disabled="!!resendCountDown || disabled"
        :loading="loading"
        @click="sendVerificationCode"
      >
        {{ resendCountDown ? `重新發送(${resendCountDown}s)` : '發送驗證碼' }}
      </g-button>
    </div>

    <div v-if="showVerificationCodeInput" class="flex mb-1">
      <input
        class="flex-1 border-2 rounded-lg text-sm px-4 py-1 border-primary text-primary appearance-none"
        :class="{ 'border-error': errors.includes('verification_code') }"
        placeholder="請輸入驗證碼"
        v-model.trim="form.verification_code"
      />
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

import authProviderMixin from '@/mixins/authProvider'

export default {
  name: 'Phone',

  components: {
    GButton,
  },

  mixins: [authProviderMixin],

  props: {
    disabled: Boolean,
    isLogin: Boolean,
  },

  data() {
    return {
      resendCountDown: null,
      recaptchaVerifier: null,
      confirmationResult: null,
      solvedRecaptcha: false,

      counter: null,

      showVerificationCodeInput: false,

      loading: false,

      form: {
        phone_number: '',
        verification_code: '',
      },

      errors: [],
    }
  },

  watch: {
    'form.phone_number'() {
      this.removeError('phone_number')
    },

    'form.verification_code'() {
      this.removeError('verification_code')
    },
  },

  methods: {
    sendVerificationCode() {
      this.errors = []

      if (!this.form.phone_number) {
        this.errors.push('phone_number')
      }

      if (this.errors.length > 0) {
        this.$store.commit('FLASH_MESSAGE', {
          message: '請填妥所有資料',
          duration: 5000,
          level: 'error',
        })

        return
      }

      this.loading = true

      this.$auth()
        .signInWithPhoneNumber(
          '+852' + this.form.phone_number,
          this.recaptchaVerifier
        )
        .then(confirmationResult => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.confirmationResult = confirmationResult

          this.showResendCountDown()

          this.showVerificationCodeInput = true
        })
        .catch(error => {
          let message = '發生錯誤'
          let duration = 5000
          let level = 'error'

          switch (error.code) {
            case 'auth/captcha-check-failed':
              message = '發生錯誤，請重新載入頁面'
              break
            case 'auth/invalid-phone-number':
            case 'auth/missing-phone-number':
              message = '請輸入正確電話號碼'
              break
            case 'auth/quota-exceeded':
            case 'auth/user-disabled':
            case 'auth/operation-not-allowed':
              break
          }

          this.$store.commit('FLASH_MESSAGE', {
            message,
            duration,
            level,
          })
        })
        .then(() => {
          this.loading = false
        })
    },

    showResendCountDown() {
      // prevent duplicated call
      if (this.resendCountDown) {
        return
      }

      this.resendCountDown = 60

      this.counter = setInterval(() => {
        this.resendCountDown--

        if (this.resendCountDown === 0) {
          clearInterval(this.counter)
          this.counter = null
          this.resendCountDown = null
        }
      }, 1000)
    },

    auth() {
      return this.login()
    },

    login() {
      this.errors = []

      if (!this.form.phone_number) {
        this.errors.push('phone_number')
      }

      if (!this.form.verification_code) {
        this.errors.push('verification_code')
      }

      if (this.errors.length > 0) {
        this.$store.commit('FLASH_MESSAGE', {
          message: '請填妥所有資料',
          duration: 5000,
          level: 'error',
        })

        return Promise.reject(new Error('Invalid form'))
      }

      return this.confirmationResult
        .confirm(this.form.verification_code)
        .catch(error => {
          this.errors.push('verification_code')

          this.$store.commit('FLASH_MESSAGE', {
            message: '請輸入正確驗証碼',
            duration: 5000,
            level: 'error',
          })

          return Promise.reject(error)
        })
    },

    removeError(key) {
      this.errors = this.errors.filter(error => error !== key)
    },
  },

  mounted() {
    this.recaptchaVerifier = new this.$auth.RecaptchaVerifier('send-sms-code', {
      size: 'invisible',
      callback: () => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.solvedRecaptcha = true
      },
    })
  },
}
</script>

<style lang="stylus" scoped></style>
