<template>
  <div class="text-gray-600 text-2xs">
    <!--    <div class="flex flex-row items-start mb-1">-->
    <!--      <label-->
    <!--        class="checkbox-container h-3 w-3 cursor-pointer relative block"-->
    <!--        style="margin-top: 1px"-->
    <!--      >-->
    <!--        <input-->
    <!--          :checked="value"-->
    <!--          @change="$emit('input', !value)"-->
    <!--          class="opacity-0 absolute h-0 w-0"-->
    <!--          type="checkbox"-->
    <!--        />-->
    <!--        <span-->
    <!--          class="checkmark h-3 w-3 absolute top-0 left-0 border border-gray-700 text-primary rounded-xs"-->
    <!--          :class="{ 'border border-error': inputError }"-->
    <!--        ></span>-->
    <!--      </label>-->
    <!--      <div class="ml-2">-->
    <!--        <div class="m1-2 tracking-tighter">-->
    <!--          本人同意本公司及其合作伙伴使用本人個人資料作直接促銷用途。-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="tracking-tighter">
      登記即代表閣下承認並同意本公司所有
      <span
        class="text-primary text-bold cursor-pointer my-1"
        @click="$modal.show('privacy-policy')"
      >
        私隱政策
      </span>
      和
      <span
        class="text-primary text-bold cursor-pointer my-1"
        @click="$modal.show('terms-and-conditions')"
      >
        條款和細則
      </span>
    </div>

    <terms-and-conditions></terms-and-conditions>
    <privacy-policy></privacy-policy>
  </div>
</template>

<script>
import TermsAndConditions from '@/components/Auth/TermsAndConditions'
import PrivacyPolicy from '@/components/Auth/PrivacyPolicy'

export default {
  name: 'Agreement',

  components: {
    TermsAndConditions,
    PrivacyPolicy,
  },

  props: {
    value: Boolean,
    inputError: Boolean,
  },

  data() {
    return {
      agreement_1: '本人同意本公司及其合作伙伴使用本人個人資料作直接促銷用途。',
      agreement_2: '{0}和{1}',
    }
  },
}
</script>

<style lang="stylus" scoped>
.checkbox-container input:checked ~ .checkmark
  border-color: currentColor

  &:after
    display: block
    background-color: currentColor

.checkmark
  &:after
    content: ""
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 8px
    height: 8px
    display: none
</style>
