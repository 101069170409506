<template>
  <div
    class="bg-primary rounded-lg flex justify-between text-white p-2 mb-5 shadow-md"
  >
    <div class="flex flex-col items-start">
      <h1 class="text-yellow-accent font-bold mb-1/2">
        {{ coupon.type_value.name.zh_hk }}
      </h1>

      <expiry-count-down
        class="bg-white px-1 py-1/2"
        :expired-at="coupon.expired_at"
      ></expiry-count-down>

      <p class="mt-auto text-3xs font-bold">請在售賣機上登入後使用</p>
    </div>
    <div style="width: 123px;">
      <img
        :src="resolveAssetPath(coupon.type_value.image.zh_hk)"
        alt=""
        class="mt-1 mb-3/2"
        style="filter: drop-shadow(0 3px 10px rgba(0, 0, 0, .2))"
      />
    </div>
  </div>
</template>

<script>
import ExpiryCountDown from '@/components/ExpiryCountDown'

import pathMixin from '@/mixins/path'

export default {
  name: 'MemberCoupon',

  components: {
    ExpiryCountDown,
  },

  mixins: [pathMixin],

  props: {
    coupon: Object,
  },
}
</script>

<style lang="stylus" scoped></style>
