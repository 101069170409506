<template>
  <modal name="terms-and-conditions" :minWidth="325" width="90%" height="80%">
    <div class="w-full h-full text-primary p-6 font-bold overflow-y-auto">
      <img
        src="../../assets/img/logo_dark.svg"
        alt=""
        class="mx-auto mb-3 text-xl h-5"
      />

      <h1 class="mb-2 text-base">服務條款和細則 (簡稱“條款和細則)</h1>

      <p class="mb-2">
        Gritus售賣機、Gritus.com及其子域（統稱“網站”）由Gritus Technology
        Limited（簡稱“Gritus”）所持有。Gritus和/或其子公司和/或關聯公司
        (統稱“本公司”;
        而因應相關內文需要或許可，“本公司”亦可指Gritus和/或其子公司和/或關聯公司)
        除了通過售賣機、網站、其他的媒體平臺或網站（簡稱“平臺”）提供服務，還會透過全部或部分由本公司開發的流動應用程式（簡稱“應用程式”）向用戶提供服務。售賣機、網站、平臺和應用程式
        (簡稱或統稱“渠道”)。用戶瀏覽和使用由渠道所提供的服務必須遵守私隱政策及列於下文的條款和細則（簡稱“條款和細則”）。<br />
        通過使用渠道或經渠道進入其它網站和/或媒體平臺和/或應用程式，任何渠道的用戶（簡稱“用戶”）會被視為接受並同意遵守條款和細則及私隱政策。若用戶不接受任何條款和細則及私隱政策，請不要使用渠道。本公司保留權利在沒有預先通知的情況下，隨時修改條款和細則及私隱政策。一旦在渠道上發佈，修訂後的條款和細則及私隱政策將適用於所有用戶。本公司建議用戶應定期瀏覽此頁面，查看最新條款和細則及私隱政策。用戶一旦通知本公司不接受任何修訂，其應立即終止使用渠道和服務（定義見下文第1.a節），否則繼續使用渠道將被視為接受所有修訂並受其約束。<br />
        用戶也接受並同意，當使用渠道時，除了受本文所載的條款和細則所約束，用戶將同時受其使用的相關渠道的條款和細則及其上傳或張貼資料（定義見下文第4.a節）於本公司或關聯公司所經營之相關渠道的條款和細則所約束(如適用)。<br />
        用戶進一步接受並同意，他們應就其使用渠道遵守所有適用的法律、法規、條例和規章（不論是否具有法律效力的）。用戶了解到互聯網之全球性質並瞭解和同意他們或須遵守其司法管轄區以外適用的法律、
        法規、
        條例或規章。術語“用戶”是指所有的個人/實體在任何時候，無論是在香港或其他地方，以任何理由或目的登入和/或使用渠道。<br />
      </p>

      <ol class="list-decimal">
        <li class="mb-2">
          <div class="underline mb-1">一般條款</div>
          <ol class="list-alpha">
            <li>
              本公司根據條款和細則透過渠道向用戶提供下列一或多項服務:-
              <ol class="list-roman">
                <li>商品零售;</li>
                <li>商品送遞服務;</li>
                <li>領取和/或購買商品換領劵/優惠劵;</li>
                <li>網上購物;</li>
                <li>
                  為廣告商和/或商家提供線下和/或線上行銷（包括但不限於刊登廣告、促銷活動、優惠、行銷資訊和舉辦特別活動）;
                  及
                </li>
                <li>其它經本公司渠道提供的服務及渠道上的各項功能</li>
                <li>(以上各項服務個別或統稱"服務"）。</li>
              </ol>
            </li>
            <li>
              本公司保留自行決定修改任何服務之權利，包括但不限於刪除、添加或修改渠道的任何部分，並可不時編輯、修改、分享、刪除或移除在渠道的任何資料而不作通知。除非根據本公司已簽署的任何服務合同的條款（如有），本公司將不會就上述任何行動對任何用戶承擔任何責任。
            </li>
            <li>除條款和細則所述外，本公司不參與任何用戶之間的實際交易。</li>
            <li>
              本公司致力於保護用戶的私隱。本公司根據私隱政策中所描述的條款使用用戶的信息。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">註冊成為會員</div>
          <ol class="list-alpha">
            <li>
              用戶可以在渠道上自由瀏覽資訊並通過渠道以使用本公司提供的服務。然而，某些內容與渠道的功能，只提供給已註冊成為渠道的會員使用（簡稱“會員”）。
            </li>
            <li>
              用戶可以通過
              “Gritus會員註冊”或可以利用Facebook或Google帳戶註冊成為會員。用戶保證在註冊成為會員時所提供的個人資料都是真實、準確、現行、完整、在各方面都沒有誤導成份的，並進一步承諾該資料如日後有任何變更，會及時與本公司更新。如用戶未能遵守這條款，本公司有權暫停和/或終止其會員帳戶，而不作另行通知。
            </li>
            <li>
              用戶不可選擇一些侵犯他人權利或本公司認為帶有攻擊性、不正當的或不適當的用戶名稱或密碼。如有用戶違反，本公司將保留修改或刪除用戶名稱或密碼和/或終止他/她的會員帳戶及會員資格的權利。在任何情況下，本公司將不會就任何用戶不正當使用用戶名稱或密碼而承擔責任。
            </li>
            <li>
              用戶須獨自地和全面地負責保密其會員賬戶登錄信息和密碼和關於其會員帳戶的任何其他信息，並對與會員帳戶相關的所有活動負責。在任何情況下，本公司絕不會就因未經授權使用的會員帳戶而對任何人造成任何損失或損害承擔責任。
            </li>
            <li>
              用戶同意立即通知本公司如發現任何未經授權而使用其會員帳戶或使用本服務的任何其他保安漏洞。
            </li>
            <li>
              本公司有絕對酌情權基於任何理由拒絕任何用戶根據第2.a條申請成為會員，而用戶同意本公司沒有義務向用戶發送任何拒絕會員註冊申請的通知或解釋。
            </li>
            <li>本公司保留絕對的權利隨時取消或使任何用戶停用的會員帳戶。</li>
            <li>
              本公司根據私隱政策處理用戶提供的資料。本公司強烈建議用戶在註冊前詳細閱讀私隱政策內的條款。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">服務產品推廣</div>
          <ol class="list-alpha">
            <li>
              我們的售賣機擁有「服務產品推廣」板面提供「產品預訂」和「查詢產品」服務。而「產品預訂」和「查詢產品」服務的內容則由「服務提供者」修訂。本公司不會為「服務提供者」所提供的任何服務負責。目前「服務產品推廣」板面之板塊如下：
              <ol class="list-roman">
                <li>教育專區</li>
              </ol>
            </li>
            <li>
              閣下須於使用服務時提供閣下的個人資料以作登記。一經登記，即表示閣下聲明（而我們有權據此依賴有關聲明）閣下年滿18歲或以上，並有構成具法律約束力合約的能力。當登記時，閣下必須提供準確、完整及最新的登記資料。
            </li>
            <li>
              閣下於「服務產品推廣」板面進行「產品預訂」時，產品的價格和服務以「服務提供者」最後的更新資料為準。當付款時，所有產品價格均以港幣計算。「服務提供者」可全權酌情就任何理由不接納或取消訂單。
            </li>
            <li>
              閣下於「服務產品推廣」板面進行「查詢產品」時，所有閣下提供的資料均會開放給「服務提供者」進行聯絡。
            </li>
            <li>
              在智能售賣機上展示的產品僅供參考，產品的價格和服務以「服務提供者」最後的更新資料為準。
            </li>
            <li>
              本公司只負責為「服務產品推廣」之產品進行收集資料及收款。如閣下對產品本身有任何疑問，或與「服務提供者」及其產品或服務有任何爭議，閣下須自行與「服務提供者」處理。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">付款</div>
          <ol class="list-alpha">
            <li>
              若使用智能售賣機付款，本公司接受八達通、支付寶、微信支付、雲閃付、中銀香港移動支付等付款方式。所有訂單將會以港幣結算。
            </li>
            <li>
              我們使用第三方付款服務以進行網上交易。當閣下進行付款時，閣下同意及接納，受其條款及細則所限，閣下的信用卡資料將會被付款服務供應商收集、處理及保留。閣下同意及接納，閣下單獨及獨自承擔閣下於進行信用卡交易時所招致或產生的任何損失，且我們於任何情況下均毋須承擔任何有關全部或部分損失。
            </li>
            <li>我們不會收集或保留閣下的信用卡資料。</li>
            <li>
              以八達通、支付寶、微信支付、雲閃、中銀香港移動支付「服務產品推廣」的產品付款上限為每宗交易港幣
              10,000 元正。
            </li>
            <li>本公司保留權利於任何時間更改付款方式而不作另行通知。</li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">禁止條款</div>
          用戶(包括會員)同意不使用任何渠道進行下列明確禁止的用途：
          <ol class="list-roman">
            <li>
              所有用戶禁止干擾、擾亂或侵犯，或試圖干擾、破壞或企圖違反渠道上售賣機、伺服器或網路的安全性，包括但不限於登入未經授權的伺服器或帳戶，試圖探查、掃描或測試系統或網路的脆弱性或未經適當授權而試圖違反安全或認證措施，企圖干擾任何用戶、主機、售賣機或發送未經認可的電子郵件。如用戶違反系統或網路安全可能引致民事和/或刑事的法律責任。
            </li>
            <li>用戶不得刪除或修改其他用戶發佈的任何材料或資訊。</li>
            <li>
              用戶不得使用渠道（1）上傳、張貼、發佈、傳輸、分配、流通或儲存違反任何現行法律或規例的資料；或（2）以任何方式侵犯版權、商標、商業秘密或他人權利；或（3）以任何方式違法、對人身傷害、誹謗、淫穢、歧視、騷擾、威脅、辱駡性的，或以其他方式攻擊或令人反感的行為；或（4）作出除依據本條款和細則或本公司和用戶之間已簽訂的任何服務合同的條款以外的其他商業目的。所有用戶不得列印、下載、複製或以其他方式使用其他用戶的個人身份資訊(如有)。用戶不可使用任何形式向其他用戶發送未經授權的通信騷擾。
            </li>
            <li>
              用戶如果不具有法律能力構成具有法律約束力的合同或其被法律禁止使用服務，不應使用此渠道。
            </li>
            <li>
              用戶不得經渠道發佈任何包含虛假記載、誤導或誹謗性內容、任何電腦病毒、木馬、蠕蟲的電腦檔和可能中斷、破壞或限制任何電腦軟、硬體或電信設備的任何材料。
            </li>
            <li>
              用戶不得上傳或張貼任何材料涉及銷售或提供任何非法產品或服務或任何含有欺詐成分的資訊。
            </li>
            <li>
              用戶不得濫發資訊，包括但不限於以任何形式張貼、發送或散播任何不明的電子郵件或訊息。
            </li>
            <li>未經他人的明確允許，用戶不應登入或使用其他用戶的個人資料。</li>
            <li>
              未經本公司事先書面同意，用戶不得向任何第三方分配或轉讓任何其在條款和細則下的權利或義務。
            </li>
            <li>會員不應允許他人通過其用戶名稱和密碼來登入或使用本渠道。</li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">可接受的渠道使用</div>
          <ol class="list-alpha">
            <li>
              特定用途 —
              適用於在渠道上發佈照片，文章，觀點，評論，留言和/或其他任何資訊（統稱“資料”）的用戶。發佈資料的用戶以下簡稱為“發佈用戶”。
              <ol class="list-roman">
                <li>
                  發佈用戶同意僅用渠道於合法用途以及享受渠道所提供的服務。本公司可按其意願保留對發佈在渠道上的所有資料的編輯、拒絕、否決及刪除的權利。
                </li>
                <li>
                  若發佈用戶為個人用戶，他/她不可將其身份證和/或護照號碼發佈在渠道上。
                </li>
                <li>
                  雖然本公司將使用合理方法確保只限本公司員工接觸發佈用戶個人資料的資料庫，但是本公司並不保證其他人士將不會在沒有本公司允許的情況下接觸該資料庫。對於發佈用戶提供的個人資料的使用和保護，請參照
                  私隱政策。
                </li>
                <li>
                  發佈用戶需對其通過渠道上傳、發佈或分享的資料、以及其連結到渠道的任何網頁，媒介平臺和/或應用程式負全部責任。本公司按其意願保留編輯，拒絕，刪除任何資料和連結到網頁和/或媒介平臺和/或應用程式的權利。本公司有權自行決定終止向任何發佈用戶提供任何服務。若發佈用戶在渠道發佈資料後將資料刪除或終止其與渠道設立的帳戶，或本公司刪除任何已發佈的資料，則該發佈用戶將無法通過其帳戶接觸該等資料；然而，被刪除的資料仍可持續出現在渠道的任一部分，和/或被本公司以其他任何形式使用。
                </li>
                <li>
                  本公司保留權利在不給予任何理由的情況下以通知要求任何用戶立即停止使用其在渠道設立的帳戶，或更改其用戶名稱；若用戶拒絕遵守本公司提出的要求，本公司可自行決定隨時在沒有預先通知的情況下關閉發佈用戶帳戶，而此行動並不損害本公司其他權利。
                </li>
                <li>
                  用戶使用任何渠道須確認並同意以下條款：
                  <ol>
                    <li>
                      若用戶使用渠道上傳、發佈或分享資料，包括但不限於立即上傳、發佈和分享自拍照片，用戶同意資料是會共享的；
                    </li>
                    <li>
                      即使本公司沒有提供有關用戶的資訊，但用戶在使用渠道時，其個人識別資訊可能會被公開；以及
                    </li>
                    <li>
                      用戶將在自願選擇和明確風險的情況下使用渠道。用戶將使本公司和其執行官、股東、僱員、代理人、承包商和關聯人士免受其因使用渠道而導致損害，並就其因用戶使用渠道而產生的任何損失、損害、訴訟、索賠作出賠償。
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              特定用途 —
              適用於瀏覽和/或使用在渠道上的資料的用戶（簡稱“瀏覽者”）<br />
              <br />
              瀏覽者同意其只使用渠道於合法用途和用來享受其所提供的服務。瀏覽者同意通過渠道或從本公司接收到的任何個人資料，只會用作鑒定和/或搜尋廣告或材料或其內容，或使其享受渠道所提供服務。任何接收到的個人資料與上述目的無關的，包括但不限於商業目的，需被忽視，且不可以任何形式儲存，收集，處理，使用，傳播。瀏覽者亦同意在上述目的失效或已完成的情況下，立即完全刪除從渠道或從本公司所收集的所有個人資料。<br />
              <br />
              若任何用戶違反條款和細則，無論是在香港或其他地方，使用任何其他用戶個人資料、資訊或資料（無論是否從渠道獲得）本公司將不承擔任何責任。所有用戶接受其公開在渠道上提供的所有個人資料、資訊或資料都是自願提供，且個人承擔所提供資料的所有風險。本公司將不負責保護其公開提供的該等個人資料或資訊。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">內容許可</div>
          通過在渠道上發佈資料,
          用戶無條件授予本公司非獨家的、全球的、不可撤銷、無版稅版權、行使其資料之著作權，發表和數據庫權
          （但沒有其他權利），
          以便本公司在任何地方，無論有沒有商業收益，以任何形式使用、發佈、託管、展示、推廣、複製、下載、提交、傳播、複製，轉移、編輯、銷售和再利用資料，及實施載於私隱政策中和本文所述的用途。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">知識產權</div>
          渠道的所有內容,包括不限於文本、圖片、資訊、評論、版面、資料庫、照片、聲音、視頻格式、軟體、品牌和HTML
          均是本公司或用戶的知識產權（視情況而定）
          ，這些均受版權和商標法保護而不可被下載、 複製、 修改、 轉載、
          列印或以任何方式傳送，用戶下載及/或列印一份此類材料作為其個人合法和非商業用途或根據本公司與用戶已簽訂的服務合同範圍內而下載和/或列印此類材料除外。除條款和細則及任何特定渠道的條款和細則或與本公司簽訂的條款訂定之用途外，用戶嚴格禁止使用任何上述之內容，本公司保留與此相關的所有權利。用戶認同他們將單獨負責因違反版權法及/或其他知識產權法所引致的任何不良後果。<br />
          <br />
          為免生疑問，於任何第三方聲稱同意用戶使用條款和細則提到的內容及資料下，用戶亦不得免除此文所施加的限制。<br />
          <br />
          用戶同意本公司可以自由地使用、 披露、
          採用和修改所有其通過渠道而提供的想法、概念、提案、建議及其它交流及資訊而毋須因此給予其任何報酬或補償。用戶謹此放棄就本公司使用、披露、採用及/或修改其想法、概念、提案、建議及其它交流及資訊而向本公司索取任何報酬、收費、特許權使用費、
          手續費及/或其他費用的權利。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">內容</div>
          用戶確認本公司可能不會預先過濾或預先授權通過渠道登載或發送之任何內容。在任何情況下，本公司對渠道上的內容或通過渠道發送的內容不承擔任何責任，對任何內容或資料的丟失，以及上述內容或資料的準確性不提供任何保證。<br />
          <br />
          若本公司認為用戶違反或有可能違反任何特定渠道的條款或本公司與用戶簽訂的服務合同之條款和細則，本公司將有權刪除在渠道上已上載或發佈的任何資料並驅逐和防止用戶再使用渠道
          （例如:包括但不限於終止會員帳戶及阻止用戶進入互聯網通信協定位址），並無需對用戶作出任何補償。<br />
          <br />
          用戶同意本公司在遵守私隱政策的條款下，使用用戶在渠道上已提供的個人資料和其他資訊以提供本公司有關的服務、行銷服務或特別活動。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">責任</div>
          本公司或不會在任何時間都監控渠道但保留此權利。本公司不保證任何資料或網頁或應用程式是否被特定數量的用戶或任何特定用戶瀏覽。在渠道的使用上，本公司不會作為任何用戶的代理，對因使用渠道或其他任何原因造成的直接或間接損害不會承擔任何責任。本公司竭力向用戶提供優質服務，但不保證渠道的操作無失誤或渠道和伺服器完全無病毒或無其他損害裝置。<br />
          <br />
          若因使用渠道或其內容導致用戶需要維修或替換設備或資料，用戶需自行承擔相關費用。渠道和其內容是按照“目前的模式”提供，在模式種類上沒有任何保證。
          在法律允許的最大限度內，本公司對渠道內容、服務、文本、圖像和連結的適用性、適合特定用途性、準確性和可靠性、完整性或及時性不作任何保證。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">自身風險</div>
          所有用戶需就使用渠道及通過渠道瀏覽其他網頁和/或媒體平臺和/或應用程式而承擔自身風險。
          所有用戶須對其發佈行為負責，本公司不保證用戶在渠道上發佈資料的真實性、準確性和可靠性，亦不代表本公司贊同發佈用戶表達的觀點。在用戶違反條款和細則內容或侵犯法律的情況下，本公司保留權力排除該用戶並禁止用戶再次登入渠道，亦保留權利可自行決定刪除任何具有辱駡性、非法性、分裂性或不適當的資料。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">賠償</div>
          所有用戶同意保障及免除本公司、其執行官、董事、僱員、代理、合作商、代表、股東、服務人員、律師、前任、繼任人員和受讓人，因用戶使用渠道或通過使用本公司提供的任何服務或違反任何條款和細則或特定渠道之條款或本公司和用戶之間所簽訂的任何服務合同而產生的任何索賠、行為、要求、責任、損失和費用等方面的責任（包括全額賠償基準上的法務費用和訴訟費用）。如有任何此類索賠，訴訟或法律程序的，本公司將及時通知相關用戶。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">服務範圍</div>
          本公司有權限制服務的使用，包括資訊在渠道發佈的期限、尺寸、位置和佈局、郵件資訊或任何通過服務傳輸的內容。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">服務終止</div>
          本公司有權因任何原因(包括但不限於用戶違反任何法律、條款和細則、渠道或任何特定平臺之條款和細則或本公司與用戶簽署的任何服務合同之條款和細則)及沒有通知的情況下刪除或停用任何帳戶或屏蔽任何用戶的郵件或IP
          地址, 或終止對用戶的服務,
          或移除服務內的資料。本公司保留其認為適當或必要時，在任何時候採取以上有關行動,
          包括但不限於向該用戶採取法律行動。本公司沒有義務在停止服務或删除渠道中的資料之前或之後，向任何用戶傳達渠道上發佈的任何資料。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">不可抗力</div>
          倘若由於任何「不可抗力」的原因（包括由於火災、傷亡、意外、天災、自然災害、任何香港特別行政區政府或其任何機關的法律、命令、宣告、規例、要求或規定、罷工、勞資爭議、勞工短缺或技術工人短缺或任何其他
          Gritus 合理控制範圍以外的理由（不論是否與前述理由相似）），導致 Gritus
          不能履行其在網站及售賣機的責任，Gritus
          在該等妨礙的範圍内無須就此承擔責任。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">免責聲明</div>
          本公司不控制及不能確保通過渠道上進入的第三方網站和/或媒體平臺和/或應用程式發佈的任何內容之真實和準確性。<br />
          <br />
          本公司概不負責因超出其合理控制範圍的事件（包括但不限於網路故障或任何系統預設）可能引致的任何直接或間接的損失。<br />
          <br />
          本公司不能保證用戶可不間斷的、
          連續的和/或安全地通過渠道使用服務。部分或全部渠道可能於某時段內沒有原因意外地不能接達，這些原因包括但不限於可能是系統故障及中斷，互聯網接達故障及其他本公司無法控制的技術問題，對此本公司概不負責。本公司保留因不同的原因，包括系統維修或提升而將渠道的任何部分或全部離線而不需作出通知的權利。<br />
          <br />
          <strong
            >在任何情況下，本公司、其執行官、董事、僱員、代理、合作商、代表、股東、服務員、律師，前任和繼任人員任絕不需因
            (i)
            用戶使用或無法使用渠道的內容或服務，包括因疏忽，或因各方的分歧;（ii）
            用戶使用任何廣告商或商家提供的服務或產品 ; 和/或（iii）
            任何廣告商或商家行為，提供的服務或政策，而直接或間接引起或造成的任何損失、
            索償或損害賠償（包括但不限於直接、 間接、 偶然、 特殊、
            懲罰性或相應的損害賠償，業務或利潤損失）負任何責任。</strong
          ><br />
          <br />
          <strong>本公司並不是任何商家或廣告商的代理。</strong>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">責任範圍</div>
          在不影響上文及符合適用法律的基礎上，本公司對任何用戶因使用服務和/或渠道而提出所有索償和費用的整體責任上限為港幣100元。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">安全措施</div>
          本公司將盡其合理努力，確保其執行官、董事、僱員、代理、或承包商審慎細緻地處理用戶提交的個人資料，確保接觸和處理個人資料的人是基於“需要瞭解”和“需要使用”的基礎上。本公司將努力保護個人資料免受非授權接觸、處理和刪除。有關本公司如何使用和保障用戶通過渠道上提供的個人資料，請參閱私隱政策。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">可分割性</div>
          本條款和細則的每一條款獨立於其他條款，當條款和細則的任何條文已屬或變成違法、無效或不可執行時，其他條款的有效性不受影響。並以現行使用法律下的一個或多個合法、有效和可行條例加以替換。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">矛盾</div>
          若(i) 此條款和細則， (ii) 平臺和/或應用程式之特定使用條款，(iii)
          私隱政策; 與
          (iv)本公司與任何用戶簽署的服務合同之條款包括服務合同或服務訂購，和/或本公司主辦的任何特殊活動的任何具體使用之條款和細則相牴觸，則以第
          (iv) 項優先於其他文件，而其餘優先順序如下：(i) 私隱政策; (ii)
          平臺和/或應用程式之特定使用條款; 及 (iii)
          條款和細則，但受限於上述文件中明確的相反規定（如有）。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">管轄法律</div>
          本條款受香港特別行政區法律管轄及按其詮釋。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">第三方權利</div>
          除本條款和細則明確規定外，其他非本條款和細則的關聯人士均沒有強制執行任何條款的權利。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">歧義</div>
          如中文版本與其他語言的版本有任何歧義，概以中文版本為準。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">聯絡方法</div>
          如有任何疑問，請致電
          <a href="tel:+85239968235">3996 8235</a>
          ，或郵寄至九龍土瓜灣土瓜灣道 82-84 號環凱廣場 6 樓 602 室。
        </li>
      </ol>
    </div>

    <div class="absolute top-0 right-0 p-2 cursor-pointer" @click="close">
      <i class="material-icons text-sm text-primary">close</i>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'TermsAndConditions',

  methods: {
    close() {
      this.$modal.hide('terms-and-conditions')
    },
  },
}
</script>

<style lang="stylus" scoped>
ol
  padding-left: 0.75rem
</style>
