import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flashMessage: null,

    loading: false,

    authInitialized: false,

    user: null,

    userData: null,

    emailUnverified: false,
  },

  mutations: {
    FLASH_MESSAGE(state, flashMessage) {
      state.flashMessage = flashMessage
    },

    CLEAR_FLASH_MESSAGE(state) {
      state.flashMessage = null
    },

    AUTH_INITIALIZE(state) {
      state.authInitialized = true
    },

    UPDATE_USER(state, { user }) {
      state.user = user
    },

    UPDATE_USER_DATA(state, data) {
      state.userData = data
    },

    SHOW_LOADING(state) {
      state.loading = true
    },

    HIDE_LOADING(state) {
      state.loading = false
    },

    SET_EMAIL_UNVERIFIED(state) {
      state.emailUnverified = true
    },
  },
  actions: {},
})
