<template>
  <div class="px-4 flex flex-col">
    <div class="self-center mb-6 text-sm sm:text-base">
      <div class="mb-1">
        在售賣機上購買貨品可獲得印花
      </div>
      <div class="text-error flex flex-row">
        <span>集齊印花即可換領優惠券</span
        ><g-button
          class="ml-2 btn rounded-full bg-gray-900 text-white text-2xs sm:text-xs"
          @click="$modal.show('stamp-terms')"
        >
          詳情
        </g-button>
      </div>
    </div>

    <div class="flex flex-row items-center mb-2">
      <div class="flex-1 text-primary">
        你已收集 {{ verifiedStampCount }} 個印花
      </div>
      <div class="flex-none">
        <router-link :to="{ name: 'coupons' }" class="btn btn-primary btn-xs">
          換領優惠券
        </router-link>
      </div>
    </div>

    <div class="flex flex-row flex-wrap -mr-2">
      <div
        v-for="i in stampCount"
        :key="`stamp_${i}`"
        class="w-1/5 flex flex-row items-center mb-4"
      >
        <stamp-slot class="flex-1 min-w-0" :stamp="stamps[i - 1]"></stamp-slot>
        <div
          class="flex-none w-2 sm:w-4 text-center text-base sm:text-xl"
          :class="{
            'text-primary': stamps[i - 1],
            'text-gray-500': !stamps[i - 1],
            invisible: i && i % 5 === 0,
          }"
        >
          &#9656;
        </div>
      </div>
    </div>

    <stamp-terms></stamp-terms>
  </div>
</template>

<script>
import { get, orderBy } from 'lodash'

import StampSlot from '@/components/Stamp/Slot'
import StampTerms from '@/components/Stamp/Terms'
import GButton from '@/components/GButton'

export default {
  name: 'StampCollection',

  components: {
    GButton,
    StampSlot,
    StampTerms,
  },

  data() {
    return {
      minStampCount: 10,
    }
  },

  computed: {
    stamps() {
      const stamps = get(this.$store.state.userData, 'stamps', [])
      return orderBy(stamps, ['expired_at'], ['asc'])
    },

    stampCount() {
      return Math.max(this.stamps.length, this.minStampCount)
    },

    verifiedStampCount() {
      return this.stamps.filter(stamp => stamp.verified_at).length
    },
  },
}
</script>

<style lang="stylus" scoped></style>
