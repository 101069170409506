import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import store from '@/store'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

export default {
  install(Vue) {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig)

    const auth = firebase.auth()

    auth.onAuthStateChanged(user => {
      store.commit('AUTH_INITIALIZE')

      store.commit('UPDATE_USER', { user })

      firebase.analytics().setUserId(user ? user.uid : null)
    })

    Vue.prototype.$auth = firebase.auth
    Vue.prototype.$analytics = firebase.analytics
  },
}
