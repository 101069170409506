<template>
  <div class="flex overflow-hidden">
    <i
      v-if="!sentResetEmail"
      class="absolute material-icons top-0 left-0 p-2 cursor-pointer"
      @click="$router.back()"
      >arrow_back</i
    >

    <transition name="slide-left" mode="out-in">
      <form
        v-if="!sentResetEmail"
        @submit.prevent="submit"
        class="flex-1 w-full"
      >
        <img
          src="../../assets/img/forget_password.png"
          class="mx-auto"
          alt=""
        />

        <p class="text-center my-3 text-primary font-medium">
          忘記密碼了嗎?<br />請使用Email重設密碼
        </p>

        <div class="flex my-12">
          <input
            class="flex-1 border-2 rounded-lg text-sm px-4 py-1 border-primary text-primary appearance-none"
            type="email"
            placeholder="電郵地址"
            v-model.trim="form.email"
          />
        </div>

        <g-button
          type="submit"
          class="btn btn-lg btn-primary block text-xs"
          :loading="loading"
          :disabled="loading"
          >發送</g-button
        >
      </form>

      <div v-else class="flex-1 w-full">
        <img
          src="../../assets/img/email_sent.png"
          class="mx-auto mt-6"
          alt=""
        />

        <p class="text-center my-3 text-primary font-medium">
          重設密碼Email已經發出
        </p>

        <div class="flex mb-24">
          <input
            class="flex-1 border-2 rounded-lg text-base text-center px-4 py-1 border-primary text-primary appearance-none"
            type="email"
            placeholder="電郵地址"
            readonly
            disabled
            :value="form.email"
          />
        </div>

        <router-link
          :to="{ path: '/login', query: $route.query }"
          class="btn btn-lg btn-primary block text-xs"
        >
          返回
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'ForgetPassword',

  components: {
    GButton,
  },

  data() {
    return {
      form: {
        email: '',
      },

      loading: false,

      sentResetEmail: false,
    }
  },

  methods: {
    submit() {
      this.loading = true

      this.$auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          this.sentResetEmail = true
        })
        .catch(error => {
          this.$sentry.captureException(error)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
