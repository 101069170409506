<template>
  <modal name="privacy-policy" :minWidth="325" width="90%" height="80%">
    <div class="w-full h-full text-primary p-6 font-bold overflow-y-auto">
      <img
        src="../../assets/img/logo_dark.svg"
        alt=""
        class="mx-auto mb-3 text-xl h-5"
      />

      <h1 class="mb-2 text-base">個人資料政策聲明（簡稱“私隱政策”）</h1>

      <p class="mb-2">
        Gritus Technology Limited和/或其附屬公司和/或關聯公司（統稱“本公司”;
        而因應相關內文需要或許可，“本公司”可指Gritus Technology
        Limited和/或其附屬公司和/或聯屬公司)
        承諾遵守香港法例第486章“個人資料（私隱）條例”的規定。本公司將確保其員工對用戶通過售賣機、Gritus.com、其子域、任何其他由本公司經營/開發的網站、媒體平臺或應用程式包括流動應用程式和流動網絡（個別或統稱“渠道”）提交的所有個人信息和數據遵守最嚴格的安全和保密標準。不經渠道用戶同意(無論註冊與否)(
        簡稱“用戶”)，本公司不會向任何人洩露此等個人資料，惟以下第3條“資料的披露或傳遞”中列出的獲授權人士除外。<br />
        本公司強烈建議用戶仔細閱讀本私隱政策以便瞭解本公司對於用戶在渠道上提供的個人資料的相關政策和實際應用。本私隱政策適用於註冊和非註冊用戶，並且當本公司認為必要時可不定期更新、修訂、更改和/或修改相關條款內容。<br />
        如果用戶對該私隱政策有任何疑問或顧慮，請聯絡本公司的客戶服務代表。有關進一步詳情，請參閱下述第13條關於“聯絡方法”的條款。<br />
      </p>

      <ol class="list-decimal">
        <li class="mb-2">
          <div class="underline mb-1">收集個人資料的目的</div>
          <ol class="list-alpha">
            <li>
              在使用渠道的過程中，用戶可能會透露或會被要求提供個人資料和/或數據。為了用戶可享受渠道提供的各類服務，用戶可能需要向本公司提供其個人資料和/或數據。雖然用戶不必應要求通過渠道提交個人資料，惟若用戶拒絕提交其資料，本公司將不能向其提供若干渠道上的服務。
            </li>
            <li>
              本公司在渠道中收集個人資料的目的包括但不僅限於以下幾點：
              <ol class="list-roman">
                <li>為用戶提供服務的日常運作；</li>
                <li>
                  辨認及確認在渠道上發佈廣告、材料、信息、照片、評論、意見或其他資訊（統稱“資訊”）的用戶身份；
                </li>
                <li>識別瀏覽過渠道上資訊的用戶；</li>
                <li>識別在渠道上購買商品和/或服務的用戶；</li>
                <li>為用戶提供本公司提供的服務；</li>
                <li>核實透過渠道上使用服務的用戶身份；</li>
                <li>處理及跟進服務來電、查詢、申請和投訴；</li>
                <li>
                  向用戶提供活動信息、營銷和宣傳資訊（有關進一步詳情，請參閱下述第4條關於“通訊、宣傳資訊、營銷資訊訂閱”的條款）；
                </li>
                <li>辨別用戶通過營銷和宣傳資訊已享受過其為渠道會員之福利；</li>
                <li>作為會員福利，允許渠道會員參加本公司舉辦的特別活動；</li>
                <li>
                  就有關用戶於本公司之賬戶的行政通知和通信而聯絡有關用戶和/或就用戶通過渠道使用本公司提供的任何服務而發出有關的通知及確認；
                </li>
                <li>
                  為用戶設計、發展和改善產品和服務統計渠道使用量的資料並分析渠道及服務使用情況以供本公司內部使用；
                </li>
                <li>
                  使用用戶的個人資料以促進本公司為用戶提供本公司之服務、本公司及/或其客戶之營銷服務、特別活動或促銷活動。
                </li>
                <li>執行本公司的條款和細則及其他權利；</li>
                <li>以遵守法律義務和用戶同意下(如需要)或在相關法律允許; 及</li>
                <li>用於與上述用途有關的任何其他目的。</li>
              </ol>
            </li>
            <li>
              請注意，本公司的會員系統及網站並非有意為未滿十三（13）歲以下的人士設計，如您未滿13歲，在未獲得父母的事先同意的情況下，則不應向我們提供資料。
              如本公司發現未滿13歲的人士在未經父母同意的情況下向本公司提供了個人資料，我們將會立即刪除此用戶資料。倘若您認為本公司可能已收集到某些13歲以下兒童的任何個人資料，請電郵
              <a href="mailto://hi@gritus.com" class="underline">
                hi@gritus.com
              </a>
              與我們聯絡。
            </li>
            <li>
              本公司只會收集本公司認為相關且必要的個人資料；而不會就上述用途收集過量資料。
            </li>
            <li>
              除上述目的以外，本公司亦有可能需要使用用戶的個人資料作其他目的，就此，本公司會徵求用戶的事先允許。如用戶為未成年人，本公司將會向他/她的父母或監護人徵求允許。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">個人資料收集</div>
          <ol class="list-alpha">
            <li>
              本公司收集有關用戶個人資料和/或數據，如用戶姓名、用戶名、電話號碼、電郵地址、電話號碼、年齡、性別、出生日期、Facebook/Google帳戶等非公開的資料。本公司亦會偶爾可從比賽、用戶調查、特別優惠或其對招聘廣告的回覆收集額外的個人資料和/或數據。
            </li>
            <li>
              當用戶使用渠道時，本公司會自動從用戶的支付工具、電腦或設備收集信息。
              例如，本公司將收集用戶的IP位址，網頁瀏覽器軟體和推介網站。本公司還可能收集有關通過渠道進行的用戶活動的信息，如已瀏覽的網頁和瀏覽的內容，以及搜索和/或所使用的服務。
            </li>
            <li>
              當用戶利用設備使用渠道時，如用戶將私隱設置把其所在位置的信息發送到渠道，或如用戶使用售賣機，本公司將會另外收集有關用戶所在位置的信息。
              本公司可能會使用由該用戶設備或售賣機地點標明其所在位置的信息，向該用戶提供相關的資訊以促進其使用渠道的體驗。
            </li>
            <li>
              若非渠道註冊會員的用戶通過任何渠道使用商品送遞服務時，本公司將會收集和儲存有關該用戶的個人資料和/或數據，包括他/她的姓名，地址和電話號碼。
            </li>
            <li>
              只有本公司授權的員工才會被允許接觸用戶的個人資料和數據，除了下述第3條
              “資料的披露或傳遞”
              所列出的情況外，本公司不得向任何第三方披露此等個人資料和數據。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">資料的披露或傳遞</div>
          <ol class="list-alpha">
            <li>
              根據下列條款，本公司將採取各種可行措施以保障所有用戶個人資料和數據免其遭披露。
            </li>
            <li>
              一般來說，本公司只將用戶的個人資料和/或數據披露和/或提供予本公司內部員工用作上述“收集個人資料的目的”
              內提及的目的。然而，在下列情況下，本公司可能會將其資料和/或數據傳遞給第三方：
              <ol class="list-roman">
                <li>
                  在保密原則下，當資料和/或數據披露和/或提供給任何本公司授權的第三方供應商或外部供應商，以促進渠道所提供的服務;
                </li>
                <li>
                  當資料和/或數據披露和/或轉讓予本公司授權的任何代理人或其關聯公司，經本公司授權使用該等資料和/或數據；
                </li>
                <li>在條款和細則允許的情況下;</li>
                <li>本公司需要保護和捍衛其權利和財產；</li>
                <li>
                  本公司認為根據適用的法律法規，包括但不限於司法程序，法院命令，或渠道上的法律程序，而有必要提供；
                </li>
                <li>
                  當本公司認為有必要以傳送本公司供應的服務及用以維持和改善渠道的服務；及
                </li>
                <li>
                  如本公司在任何合併、融資、收購、破產、解散、交易或訴訟談判過程中出售、轉讓、剝離或披露其全部或部分業務或資產給另一家公司，則本公司可能會向第三方披露或提供用戶資料。這第三方將需要作出適當的保密和安全措施。
                </li>
              </ol>
            </li>
            <li>
              通過渠道所收集的個人資料可以在本公司運作的任何國家內轉移，儲存或處理。一旦使用渠道，用戶將被視為同意並授權本公司在上述情況下披露和轉載他們的個人資料和數據，以及將用戶資料（包括個人資料）轉載到其所在以外的其他國家。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">通訊、宣傳資訊、營銷資訊訂閱</div>
          <ol class="list-alpha">
            <li>
              本公司會不時根據用戶在渠道上提供的個人資料和數據通過電子郵件和/或短信服務（SMS）向其傳送通訊、宣傳資訊和營銷資訊，及有關本公司或其第三方廣告商的產品、服務及相關的資訊。本公司在取得用戶的允許後（包括不反對的指示）利用用戶資料或數據進行直接營銷。在這方面，請注意：
              <ol class="list-roman">
                <li>
                  本公司或其授權人和其員工或會在直接營銷中使用本公司持有的個人資料：
                  <ol>
                    <li>於 (2.a) 所述之個人資料;</li>
                    <li>閣下的年齡、性別、出生日期;</li>
                    <li>閣下的Facebook/Google帳戶</li>
                    <li>用戶通過渠道進行的交易記錄;</li>
                  </ol>
                </li>
                <li>
                  以下類別的服務，產品和項目或會被推銷：
                  <ol>
                    <li>食品、飲料和日常用品類產品服務；</li>
                    <li>
                      本公司為會員和用戶舉辦的特別活動，包括但不限於講座、研討會、競賽等形式；
                    </li>
                    <li>獎賞，忠誠或特別待遇計劃和相關的產品和服務；</li>
                    <li>優惠包括優惠券，折扣和促銷活動；</li>
                    <li>
                      由本公司和其廣告商提供的產品和服務
                      (這些實體的名稱可以在相關的產品和服務的廣告和/或營銷材料相中找到，因具體情況而定)
                      ；
                    </li>
                    <li>非牟利目的的慈善捐贈和捐款。</li>
                  </ol>
                </li>
                <li>
                  上述產品、服務和項目可由以下第三方提供或經本公司徵求（在捐贈和捐款的情況下）:
                  <ol>
                    <li>
                      第三方服務供應商提供上述第（ii）項所列的產品、服務和項目；
                      及
                    </li>
                    <li>慈善或非牟利機構；</li>
                  </ol>
                </li>
                <li>
                  除行銷上述服務、產品和項目外，本公司亦有意向上述第（iii）項中所述的全部或任何人士提供上述第（i）項所述的數據以促銷其服務、產品和項目。用於這一目的時，本公司需要徵求用戶同意（其中包括不反對的指示）;
                </li>
                <li>
                  本公司可能會為上述第（d）項所述的其他人士提供數據以換取金錢或其他財產等報酬，而當本公司徵求如上述（d）項所需的用戶同意，本公司將會通知用戶如本公司透過提供數據給其他人士而獲得金錢或其他財產的回報。
                </li>
              </ol>
            </li>
            <li>
              會員可以採取下列方法選項 “退出”
              而不再接收此類資訊。用戶可以選擇登錄會員帳戶維護頁面，或點擊相關通訊/信息的自動連結，或聯絡本公司的客戶服務代表，詳情請參閱下述第13條關於“聯絡方法”的條款。
            </li>
            <li>
              上述這種“退出”不會影響用戶接收由本公司發出有關使用本公司提供之服務的通知和信件（即不是營銷或促銷性質）。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">Cookies和日誌檔</div>
          <ol class="list-alpha">
            <li>
              除非用戶明確要求，當用戶進入並瀏覽渠道時，本公司不會收集保存任何個人身份的資料。當用戶登入渠道時，本公司僅記錄他們的登入而不收集他們的個人資料或數據。網站的伺服器軟體也只記錄功能變數名稱伺服器位址、追蹤用戶進入的頁面並將這些資訊儲存在“cookies”中，並諸如互聯網協定（IP）位址、瀏覽器類型、進/退頁面、作業系統、日期/時間戳記和點擊流量等資訊記錄在日誌檔中。所有這些都在用戶不知不覺中發生。
            </li>
            <li>
              本公司、本公司聘請的第三方供應商及服務供應商共同使用本公司的cookies及第三方cookies，如Google
              Analytics (分析)
              cookies，以基於用戶過往登入或使用渠道的記錄而優化和提供行銷資訊。
              詳情請參閱
              <a
                href="https://networkadvertising.org/managing/opt_out.asp"
                target="_blank"
                class="underline"
              >
                https://networkadvertising.org/managing/opt_out.asp
              </a>
              。
            </li>
            <li>
              本公司不會以上述方式自動收集的信息和數據與任何個人身份信息連接。本公司一般會使用自動收集信息和數據來評估渠道的受眾規模、不同渠道的受歡迎程度、追蹤本公司的促銷活動和特別項目中的用戶變動及參賽人數，測量用戶的流量模式和管理渠道。這種自動收集資訊和資料除按照以上第3條款“資料的披露或傳遞”規定中披露，不會對外披露。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">Google Analytics（分析）</div>
          <ol class="list-alpha">
            <li>
              Google
              Analytics（分析）廣告功能（簡稱“此功能”）在渠道實施。本公司以Google
              Analytics（分析）人口統計和興趣報告為基礎而實施此功能。
            </li>
            <li>
              Google
              Analytics（分析）是由Google提供的網路分析服務，它利用收集到的資料去追蹤和研究渠道之使用，用以編制用戶活動報告，並與其他Google服務共用。
              Google或會使用收集到的資料再根據背景情況以個性化其本身廣告網路的行銷資訊。
            </li>
            <li>
              有關Google在用戶使用渠道時如何使用數據的詳情，請參閱
              <a
                href="https://www.google.com/policies/privacy/partners"
                target="_blank"
                class="underline"
              >
                https://www.google.com/policies/privacy/partners
              </a>
              。<br />
              <br />
              用戶可以點擊
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                class="underline"
              >
                此處
              </a>
              停用Google Analytics（分析）。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">連結到其他網站/媒體平臺/應用程式</div>
          <ol class="list-alpha">
            <li>
              渠道會提供非本公司擁有或控制的其他網站/平臺/應用程式的連結。當用戶進入這些網站/平臺/應用程式和使用其提供的服務時，該用戶的個人資料和/或數據可能會在這些網站/平臺/應用程式中被收集。當用戶決定點擊渠道上允許用戶進入另一個網站/平臺/應用程式的任何廣告或超連結，該用戶的私密保護個人資料可能會顯露在這些其他網站/平臺/應用程式中。
            </li>
            <li>
              非註冊用戶通過他們的線上社交網路工具帳戶（包括但不限於Facebook或Google）登入或使用任何渠道將被視為已同意本私隱政策，本公司可得到他們提供予該等網路工具的個人資料，以便本公司和本公司授權人員於不同的國家為渠道使用者提供服務和營銷資料。
            </li>
            <li>
              如用戶通過他/她的Facebook帳戶登入任何渠道，本公司將會收集和儲存用戶已選擇提供給渠道的個人資料和信息，例如用戶的姓名、個人資料相片、性別、朋友列表。如用戶使用他/她的Google帳戶登入任何渠道，本公司將收集該用戶與其Google帳戶相關聯的電郵地址作為其獨有的識別號，並與該用戶使用本公司提供的服務時本公司所收集的該用戶的其他個人數據儲存在本公司的系統中。這些用戶被視為同意本公司及其授權人員根據上述規定而使用其個人資料，並/或根據上述第3條分享其個人資料，但須遵守本隱私政策的其他規定。
            </li>
            <li>
              本私隱政策只適用於渠道。在此要提醒用戶，本私隱政策並不會對用戶顯露於其他網站/平臺/應用程式的個人資料數據而提供保障。有見及此，本公司強烈建議用戶應參考該等網站/平臺/應用程式的私隱政策。為免存疑，本公司不會就就用戶使用該等其他網站/平臺/應用程式承擔任何責任或義務。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">保安措施</div>
          <ol class="list-alpha">
            <li>
              用戶的個人資料和/或數據的保障對本公司非常重要。本公司致力於確保用戶的個人資料和/或數據免受未經授權的接觸。本公司實施採用適當的電子、管理方面的措施以維護和保障用戶的個人資料安全。
            </li>
            <li>
              所有用戶的個人資料和/或數據由本公司或其授權的第三方之授權人員才可接觸，而當這些授權人員接觸此類個人信息和數據時，會遵守本私隱政策的條款。用戶可以放心，他們的個人信息和數據只為有必要履行收集信息之目的而被保留，除非用戶要求本公司停止持有其個人信息或終止他/她的會員帳戶。這樣的要求可能會導致本公司無法於將來為用戶提供進一步的服務或信息和/或宣傳資料。
            </li>
            <li>註冊用戶應將自己的用戶名和密碼保密，不與任何人分享。</li>
            <li>
              本公司遵循公認的行業標準保障用戶經渠道提交的個人資料和數據。然而，沒有一種百份之一百安全的互聯網傳輸或電子儲存方法。因此，儘管本公司致力於保護用戶的個人資料和數據免受未經授權的接觸，本公司亦不能保證其絕對安全。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">檢閱或修改數據的要求</div>
          <ol class="list-alpha">
            <li>
              本公司希望協助用戶保持其個人資料為最新及準確。要查看或編輯已儲存在渠道的個人資料，請進入Gritus.com的“會員登入”一節。
            </li>
            <li>
              任何用戶隨時都可以聯絡本公司的客戶服務代表以提交個人資料檢閱或修改數據的要求。有關進一步詳情，請參閱下述第13條關於“聯絡方法”的條款。
            </li>
            <li>
              如用戶希望檢閱或修改他/她的個人資料和數據，本公司可以要求他/她提供個人資料以驗證並確認他/她的身份。香港身份證號碼、護照號碼或商業登記證號碼是無法修改的，除非這些資料證明是不準確的。本公司將在用戶作出要求的40天內作出回應。
            </li>
            <li>
              當用戶提交檢閱其個人數據的要求而本公司認為這要求適當的情況下，本公司將僅會提供給該用戶一份個人數據的副本。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">個人資料的保存</div>
          用戶的個人資料和/或數據一經取得將安全地保存在本公司的系統中。根據法律規定，用戶的個人資料和/或數據將會由本公司保留，直至用戶以書面形式要求本公司從資料庫中刪除其個人資料和/或數據或終止其個人在渠道的會員資格。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">私隱政策的變更</div>
          <ol class="list-alpha">
            <li>
              當本公司認為必要時，本公司有權隨時按以下方式更新、修改、修訂或更改本私隱政策，強烈建議用戶頻繁地查看本隱私政策。若本公司決定更新、修改
              、修訂或更改本私隱政策，本公司將在此網頁和/或其他本公司認為合適的地方發佈該等變更資訊，以便於用戶能夠及時瞭解本公司收集的資料、本公司如何使用，
              以及在何種情況下本公司會披露該等資料。
            </li>
            <li>
              若本公司的私隱政策有重大變化，本公司將在此網頁上、透過電子郵件、或在本公司的主頁上發佈通告的方式以通知各用戶。
            </li>
          </ol>
        </li>
        <li class="mb-2">
          <div class="underline mb-1">歧義</div>
          如中文版本與其他語言的版本有任何歧義，概以中文版本為準。
        </li>
        <li class="mb-2">
          <div class="underline mb-1">聯絡方法</div>
          如有任何疑問，請致電
          <a href="tel:+85239968235">3996 8235</a>
          ，或郵寄至九龍土瓜灣土瓜灣道 82-84 號環凱廣場 6 樓 602 室。
        </li>
      </ol>
    </div>

    <div class="absolute top-0 right-0 p-2 mr-1 cursor-pointer" @click="close">
      <i class="material-icons text-sm text-primary">close</i>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PrivacyPolicy',

  methods: {
    close() {
      this.$modal.hide('privacy-policy')
    },
  },
}
</script>

<style lang="stylus" scoped>
ol
  padding-left: 0.75rem
</style>
